<template>
  <div style="cursor: pointer; height: 100%; display: flex; flex-direction: column; align-items: center;" class="pl-3 pr-2 pt-2 instance">
    <h2 style="font-size: 16px; font-weight: 600; line-height: 9px;">
      {{ nameInstance }}
      <tag v-if="statusInstance" :text="chipInfo.text" :background="chipInfo.color" text-color="white" min-width=205></tag>
      <tag v-else :text="translateStatus(lastStatus)" background="#f77676" text-color="white" min-width=205></tag>
    </h2>
    <a>{{ phoneInstance }}</a>
    <a v-if="paidTill" >Активен до {{paidTill}}</a>
    <div v-if="hasUnreadMessages" class="unread_label">
      НОВОЕ!
    </div>
  </div>
</template>

<script>
import Tag from "@/components/static/tag";
import axios from "axios";

export default {
  name: "instance",
  props: {
    ind: Number,
    nameInstance: String,
    phoneInstance: String,
    lastStatus: String,
    read_instance: Number,
    paid_till: String,
    stopped: Boolean,
    battery: Number,
    hasUnreadMessages: Boolean,
    lastDateUpdated: Object,
    tokenAccount: String,
  },
  components: {Tag},
  data() {
    return {
      paidTill: "",
    }
  },
  computed: {
    statusInstance() {
      return this.$props.lastStatus === 'authorized';
    },
    sound: function () {
      return this.list === null ? [] : this.list
    },
    chipInfo() {
      let info = {color: '#48bb78bd', text: 'OK'};
      if (this.battery < 20) {
        info.color = 'rgba(210,13,13,0.74)'
        info.text = info.text + ' (мало зарядки)';
      }
      return info
    },
  },
  methods: {
    translateStatus(status) {
      const translations = {
        authorized: 'Авторизован',
        notAuthorized: 'Не авторизован',
        blocked: 'Аккаунт забанен',
        sleepMode: 'Спящий режим',
      }

      return translations[status] || status;
    },
    isPayError(data) {
      return data <= 0 || Boolean(this.$props.stopped);
    },
    offSound(instance_id) {
      this.$store.dispatch('editSound', {'status': 1, 'id': instance_id})
    },
    onSound(instance_id) {
      this.$store.dispatch('editSound', {'status': 0, 'id': instance_id})
    },
    async updatePaidTill() {
      console.log('paidTill:', this.paidTill);
      try {
        const config = {
          headers: {
            Authorization: axios.defaults.headers.common['Authorization'],
            "X-Ga-Method": "user.instance.getSettings",
            "X-Ga-User-Id": process.env.VUE_GA_USER_ID,
            "X-Ga-User-Token": process.env.VUE_GA_USER_TOKEN,
          }};
        const tokenAccount = Number(this.tokenAccount);
        const data = { idInstance: tokenAccount};
        const url = "https://console.green-api.com/api/v1/";
        const response = await axios.post(url, data, config);
        if (response.status === 200) {
          const paidTill = new Date(response.data.expirationDate);
          this.paidTill = paidTill.toLocaleDateString();
          console.log('updated paidTill');
        }
      } catch (e) {
        console.log('error updating paidTill', e);
        this.paidTill = "";
      }
    }
  },
  mounted() {
    document.getElementsByClassName('instance')[0].classList.add('selectInstance');
    // if (process.env?.VUE_APP_GA_USER_ID && process.env?.VUE_APP_GA_USER_TOKEN) {
    //   console.log('going to make a request to green api to check expiring date')
    //   await this.updatePaidTill();
    // } else {
    //   console.log('no requested parameters for green api:', process.env);
    // }
  },
}
</script>

<style>

.unread_label {
  color: white;
  font-size: 13px;
  font-weight: 500;
  height: 15px;
  line-height: 1;
  background-color: red;
  border-radius: 15px;
  width: fit-content;
  padding: 0 5px;
}

@media screen and (max-height: 750px) {

  /*.instance {*/
  /*  line-height: 0.1;*/
  /*}*/

  .instance h2 {
    font-size: 13px;
  }

  .instance a {
    font-size: 10px;
  }

  .instance h2 span {
    font-size: 10px;
  }
}
</style>