import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from "@/views/auth";
import main from "@/views/main";
import store from "@/store";
import deny from "@/views/deny";
import statistics from "@/views/statistics";

export const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: "active",
  routes: [
    {
      path: '/',
      component: main,
      children: [
        {
          path: '',
          name: 'overview',
          props: true
        },
        {
          path: '/chat/:instance/:chat',
          name: 'chat',
          props: true
        },
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/stats',
      component: statistics,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/auth',
      component: auth
    },
    {
      path: '/deny',
      component: deny
    }
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }

  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/auth')
  } else {
    next()
  }
})

Vue.use(VueRouter)
