import axios from "axios";

const state = {
    templates: []
}
const getters = {
    getTemplates(state) {
        return state.templates
    },
    getOneTemplate: (state) => (templateID) => {
        return state.templates.find(template => template.id === templateID)
    }
}
const mutations = {
    setTemplates(state, data) {
        return state.templates = data
    },
}
const actions = {
    async getAllTemplate(context) {
        axios({
            method: 'get',
            url: process.env.VUE_APP_URL + `api/v2/template`,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(result => {
            context.commit('setTemplates', result.data)
        })
    },
    deleteTemplate(context, id) {
        axios({
            method: 'delete',
            url: process.env.VUE_APP_URL + `api/v2/template/${id}/`,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            context.dispatch('getAllTemplate')
        })
    },
    createTemplate(context, data) {
        let formData = new FormData();

        formData.append('name', data.name)
        formData.append('body_messages', data.body_messages)
        formData.append('body_messages_no_format', data.body_messages_no_format)
        formData.append('global_messages', false)
        formData.append('users', context.getters.getUserID)


        axios({
            method: 'post',
            url: process.env.VUE_APP_URL + `api/v2/template`,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            context.dispatch('getAllTemplate')
        })
    },
    editTemplate(context, data) {
        let formData = new FormData();

        formData.append('name', data.name)
        formData.append('body_messages', data.body_messages)
        formData.append('global_messages', false)
        formData.append('body_messages_no_format', data.body_messages_no_format)
        formData.append('users', context.getters.getUserID)

        axios({
            method: 'put',
            url: process.env.VUE_APP_URL + `api/v2/template/${data.id}/`,
            data: formData,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            context.dispatch('getAllTemplate')
        })
    }
}

export default {
    state, getters, mutations, actions
}
