import VueJwtDecode from 'vue-jwt-decode'
import axios from 'axios';
import {socketOnMessage} from "@/util/main";


const state = {
  id: '',
  username: '',
  lastname: '',
  first_name: '',
  instance: [],
  is_staff: false,
  selection_instance_phone: undefined,
  sound_instance: []
}
const getters = {
  getUserID(state) {
    return state.id
  },
  getUserUsername(state) {
    return state.username
  },
  getUserLastname(state) {
    return state.lastname
  },
  getUserFirstName(state) {
    return state.first_name
  },
  getUserInstance(state) {
    return state.instance
  },
  getUserInstanceSelect(state) {
    return state.instance.find(instance => instance.phone === (state.selection_instance_phone))
  },
  getInstanceIDByPhone: (state) => function(phone) {
    let obj = (state.instance.find(instance => instance.phone === phone))
    return obj ? obj.id : null
  },
  getUserStaff(state) {
    return state.is_staff
  },
  getSelectInstance(state) {
    return state.selection_instance_phone
  },
  getActiveSoundInstance(state) {
    return state.sound_instance
  }
}
const mutations = {
  editState(state, data) {
    state.id = data.id
    state.username = data.username
    state.lastname = data.lastname
    state.first_name = data.first_name
    state.instance = data.instance
    state.is_staff = data.is_staff
    state.sound_instance = data.sound_instance
    return state
  },
  editHasUnreadMessagesToInstance(state, {phone, hasUnread}) {
    let instanceObject = (state.instance.find(instance => instance.phone === phone))
    if (!instanceObject) return;
    instanceObject.has_unread_messages = hasUnread;
  },
  editSelectInstance(state, id) {
    return state.selection_instance_phone = id
  },
  editCountUnReadChat(state, data) {
    let instance = state.instance.find(instance => instance.id === data.id)

    if (instance) {
      instance.read_instance = data.count
    }
  }
}
const actions = {
  getUserInfo(context) {
    let userID;

    try {
      userID = VueJwtDecode.decode(localStorage.getItem('token'))['user_id']
    } catch (TypeError) {
      userID = null
    }
    axios({
      method: 'get',
      url: process.env.VUE_APP_URL + `api/v2/auth/getUserInfo/${userID}`
    }).then(result => {
      context.commit('editState', result.data)
      console.log('Context:', context);
      //set default Instance
      const firstInstance = context.getters.getUserInstance[0]
      context.dispatch('setSelectInstance', firstInstance['phone'])
      context.dispatch('startAlertWS')
    })
  },
  setSelectInstance(context, phone_instance) {
    context.dispatch('getChatsBase', {
      instance: phone_instance,
      status: context.getters.getSelectedStatus,
      page: '1'
    }).then(() => {
      let unreadMessaages = document.title.split(') ');
      document.title = unreadMessaages[1] || unreadMessaages;
      context.commit('editSelectInstance', phone_instance);
      context.dispatch('controlWS', {
        'instance_id': context.getters.getSelectInstance,
        'redis': process.env.VUE_APP_WS
      }).then(socket => {
        socket.onmessage = event => socketOnMessage(context, event);
    })
    })
  },
  editSound(context, data) {
    let formData = new FormData();

    formData.append('status', data.status)

    axios({
      method: 'put',
      url: process.env.VUE_APP_URL + `api/v2/updateSound/${data.id}/`,
      data: formData,
      headers: {'Content-Type': 'multipart/form-data'}
    }).then(() => {
      if (data.status) {
        state.sound_instance.push(data.id)
      } else {
        let index = state.sound_instance.indexOf(data.id);
        if (index !== -1) {
          state.sound_instance.splice(index, 1);
        }
      }
    })
  },
  startAlertWS(context) {
    document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
            context.commit('resetChatUnreadCounter', context.getters.getSelectChat.chat_id)
        }
    });
    console.log('User id from context:', context.getters.getUserID);
    let ws = new WebSocket(process.env.VUE_APP_WS + "/alert/" + context.getters.getUserID + "/?token=" + localStorage.getItem('token'));
    console.log('connected to ws:', ws)
    ws.onmessage = function (event) {
      let data = JSON.parse(event['data']);
      let type = data['type_package'];

      if (type === 'new_chat') {
        context.dispatch('getChatsBase', {
          instance: context.getters.getSelectInstance,
          status: context.getters.getSelectedStatus,
          page: context.getters.getPaginatorInfoChat.page
        })
        let audio = new Audio('/sounds/message_notify.mp3');
        audio.play()
      }

      if (type === "update_last_visit") {
        context.commit('updateChatData', {
          'chat_id': data['data']['chat_id'],
          'format_last_visit': data['data']['last_visit']
        });
      }

      if (type === 'new_message_in_instance') {
        const {instance_phone, instance_name, chat_name, chat_number} = data
        context.commit('editHasUnreadMessagesToInstance', {
          phone: instance_phone,
          hasUnread: true,
        })
        new Notification(`${chat_name} ${chat_number}`, { body: instance_name});
      }

      if (type === 'alert' && data.instance_id === context.getters.getSelectInstance && !(context.getters.getInfoChat(data['data']['chat_id']))) {
        setTimeout(() => {
          context.dispatch('getChatsBase', {
            instance: context.getters.getSelectInstance,
            status: context.getters.getSelectedStatus,
            page: context.getters.getPaginatorInfoChat.page
          })

        }, 1000)
      }

      if (type === 'alert' && data['data']['from_me'] === false && context.getters.getSelectChat.chat_id !== data['data']['chat_id']) {
        // let offSound = JSON.parse(localStorage.getItem('offSound')) ? JSON.parse(localStorage.getItem('offSound')) : []

        if (context.getters.getActiveSoundInstance.includes(data.instance_id)) {
          let audio = new Audio();
          audio.preload = 'auto';
          audio.src = '/sound/notif.mp3';
          audio.play();
        }
      }

      if (type === 'alert' && parseInt(context.getters.getSelectInstance) === parseInt(data['instance_id'])) {
        if (context.getters.getFakeMessages.find(chat => chat.chat_id === data.data.chat_id)) {
          context.commit('removeFakeMessages', {
            chat_id: data.data.chat_id,
            instance_id: context.getters.getSelectInstance,
            messages: data.data.body_messages,
            type_messages: data.data.type_messages
          })
        }

        context.commit('updateChatData', {
          'chat_id': data.data.chat_id,
          'last_text_message_chat': data.data.body_messages,
          'last_time_update_chat': data.data.time
        });

        if (!data.data.from_me) {
          context.commit('setReadChat', {'chat_id': data.data.chat_id, 'status': false});
        }
      }
    }
  }
}

export default {
  state, getters, mutations, actions
}
