<template>
<div class="container border-b">
    <button
        class="justify-center text-blue-500
        font-semibold py-1 px-1 pr-1 ml-2 mr-2
        border rounded
        custom-ring"
        style="font-size: 12px; width: fit-content;"
        :class="btnLoadingClass"
        :disabled="isLoading"
        @click="readAll"
    >
      <LoaderSpin v-if="isLoading" loader-color="text-blue-500"/>
      Отметить всё как прочитанное
    </button>
  </div>

</template>

<script>
import LoaderSpin from "@/components/other/LoaderSpin.vue";

export default {
  name: "read_all_button",
  components: {LoaderSpin},
  data() {
    return {
      isLoading: false,
      poolInterval: null,
    };
  },
  computed: {
    btnLoadingClass() {
      return !this.isLoading
        ? 'hover:bg-blue-500 hover:text-white hover:border-transparent focus:outline-none active:outline-none'
        : ''
    }
  },
  methods: {
    async readAll() {
      console.log('In readAll method');
      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const readAllResponse = await this.$http({
            method: 'get',
            url: process.env.VUE_APP_URL + 'api/v2/readAllChats/' + this.$store.getters.getSelectInstance,
          });
          console.log('readAllResponse:', readAllResponse);
          console.log('Going to reloadChats');
          await this.$store.dispatch('reloadChats');
          console.log('Going to editHasUnreadMessagesToInstance with phone:', this.$store.getters.getSelectInstance);
          this.$store.commit('editHasUnreadMessagesToInstance', {
            phone: this.$store.getters.getSelectInstance,
            hasUnread: false,
          });
          this.poolInterval = setInterval(this.checkReadingState, 10000);
      } catch (e) {
        console.log(e)
      }
      }
    },
    async checkReadingState() {
      const instance = this.$store.getters.getSelectInstance;
      if (instance) {
        try {
          const url = process.env.VUE_APP_URL + 'api/v2/instanceReadingState/' + instance;
          const response = await this.$http({
            method: 'get',
            url: url,
          })
          console.log('response of checkReadingState', response)
          this.isLoading = response.data?.is_reading_messages || false;
          if (!this.isLoading) {
            clearInterval(this.poolInterval);
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  },
  mounted() {
    this.checkReadingState();
    if (this.isLoading) {
      this.poolInterval = setInterval(this.checkReadingState, 10000);
      setTimeout(() => { clearInterval(this.pollInterval) }, 36000000)
    }
  }
}
</script>

<style scoped>
.container {
  padding-left: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.custom-ring:active {
  box-shadow: 1px 1px 5px 2px #4299e1;
}
</style>