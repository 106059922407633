<template>
  <div
      class="d-flex justify-content-center align-items-center"
      style="position: fixed; left: 0; top: 0; z-index: 60; width: 100%; height: 100%;"
  >
    <div
        class="d-flex flex-column white"
        style="background-color: white; width: 90%; height: 90%; padding: 16px; border-radius: 15px; border: 1px black solid;"
    >
      <div class="d-flex justify-content-end align-items-center white" style="width: 100%; cursor: pointer;">
        <span style="font-size: 20px; font-weight: 500;" @click="$emit('closeButtonPressed')">X</span>
      </div>
      <div class="white" style="flex-grow: 1; display: flex; justify-content: center; align-items: center; max-height: 95%;">
        <img :src="src" alt="Картинка" style="object-fit: contain; height: 100%;">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "image_full",
  props: {
    src: String,
  },
}
</script>

<style scoped>
 .white {
   background-color: white!important;
 }
</style>