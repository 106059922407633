<template>
  <div>
    <div style="margin-top: 10px; margin-bottom: 10px; background: #d0eaff;" v-show="showDialogDelete">
      <h3 style="text-align: center; color: red">Вы хотите удалить шаблон</h3>
      <p style="text-align: center">
        <a style="padding-right: 10px"
           @click="$store.dispatch('deleteTemplate', idForDelete); showDialogDelete=false; idForDelete=undefined"
           href="#">Да</a>
        <a @click="showDialogDelete=false; idForDelete=undefined" href="#">Нет</a>
      </p>
    </div>
    <!-- Создать шаблон-->
    <div style="padding-bottom: 10px;" class="border-b shadow-bot" v-if="showDialogCreate">
      <p class="text-lg font-semibold text-center text-gray-800">Создать шаблон</p>

      <div class="flex items-center w-full px-3 mt-6">
        <div class="px-2 text-gray-500 rounded-full hover:text-gray-600">
          <svg class="w-6 h-6 text-gray-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24">
            <path fill-rule="nonzero"
                  d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,11 C12.5128358,11 12.9355072,11.3860402 12.9932723,11.8833789 L13,12 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4871642,18 11.0644928,17.6139598 11.0067277,17.1166211 L11,17 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M12,6.5 C12.8284271,6.5 13.5,7.17157288 13.5,8 C13.5,8.82842712 12.8284271,9.5 12,9.5 C11.1715729,9.5 10.5,8.82842712 10.5,8 C10.5,7.17157288 11.1715729,6.5 12,6.5 Z"></path>
          </svg>
        </div>

        <div class="ml-4">
          <div class="inline-block relative w-64">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   for="name-template-message">
              Названия шаблона
            </label>
            <input v-model="nameTemplate"
                   class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded "
                   id="name-template-message" type="text">
          </div>
        </div>
      </div>
      <div class="flex items-center w-full px-3 mt-6">
        <div class="px-2 text-gray-500 rounded-full hover:text-gray-600">
          <svg class="w-6 h-6 text-gray-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24">
            <path fill-rule="nonzero"
                  d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,11 C12.5128358,11 12.9355072,11.3860402 12.9932723,11.8833789 L13,12 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4871642,18 11.0644928,17.6139598 11.0067277,17.1166211 L11,17 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M12,6.5 C12.8284271,6.5 13.5,7.17157288 13.5,8 C13.5,8.82842712 12.8284271,9.5 12,9.5 C11.1715729,9.5 10.5,8.82842712 10.5,8 C10.5,7.17157288 11.1715729,6.5 12,6.5 Z"></path>
          </svg>
        </div>

        <div class="ml-4">
          <div class="inline-block relative w-64">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   for="text-template-message">
              Шаблон с ником
            </label>
            <textarea v-model="bodyTemplate"
                      class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded "
                      id="text-template-message" type="text"></textarea>
          </div>
        </div>
      </div>


      <div class="flex items-center w-full px-3 mt-6">
        <div class="px-2 text-gray-500 rounded-full hover:text-gray-600">
          <svg class="w-6 h-6 text-gray-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24">
            <path fill-rule="nonzero"
                  d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,11 C12.5128358,11 12.9355072,11.3860402 12.9932723,11.8833789 L13,12 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4871642,18 11.0644928,17.6139598 11.0067277,17.1166211 L11,17 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M12,6.5 C12.8284271,6.5 13.5,7.17157288 13.5,8 C13.5,8.82842712 12.8284271,9.5 12,9.5 C11.1715729,9.5 10.5,8.82842712 10.5,8 C10.5,7.17157288 11.1715729,6.5 12,6.5 Z"></path>
          </svg>
        </div>

        <div class="ml-4">
          <div class="inline-block relative w-64">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   for="text-template-message">
              Шаблон без форматирования
            </label>
            <textarea v-model="bodyTemplateNoF"
                      class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded "
                      id="text-template-message-n-format" type="text"></textarea>
          </div>
        </div>
      </div>

      <div class="flex content-center items-center w-full px-3 mt-6">
        <button @click="backTemplateToList()" style="flex: auto;"
                class="justify-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
          Назад
        </button>
        <button @click="controlSend()" style="flex: auto;"
                class="justify-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
          Добавить
        </button>
      </div>
    </div>

    <!--Список шаблонов-->
    <table class="table-fixed m-1" v-if="!showDialogCreate">
      <thead>
      <tr style="text-align: center">
        <th class="w-1/2">Название</th>
        <th class="w-1/4">Дейсвие</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="template in $store.getters.getTemplates" :key="template.id">
        <td @click="useTemplate(template.body_messages, template.body_messages_no_format)" style="text-align: center">
          {{ template.name }}
        </td>
        <td v-if="template.users === $store.getters.getUserID">
          <a @click="getInfoTemplate(template.id)" href="#">Редактировать</a><br>
          <a @click="deleteTemplate(template.id)" href="#">Удалить</a>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="!showDialogCreate" class="flex content-center items-center w-full px-3 mt-6">
      <button style="flex: auto;" @click="createTemplate()"
              class="justify-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
        Создать шаблон
      </button>
    </div>

  </div>
</template>

<script>
export default {
  name: "template",
  async mounted() {
    this.$store.dispatch('getAllTemplate')
  },
  data() {
    return {
      showDialogCreate: false,
      showDialogDelete: false,
      idForDelete: undefined,

      edit: false,

      nameTemplate: '',
      bodyTemplate: '',
      bodyTemplateNoF: ''
    }
  },
  methods: {
    useTemplate(text, no_format) {
      let info = this.$store.getters.getInfoChat(this.$store.getters.getSelectChat.chat_id) ? this.$store.getters.getInfoChat(this.$store.getters.getSelectChat.chat_id) : this.$store.getters.getInfoChatChatListSearch(this.$store.getters.getSelectChat.chat_id)

      console.log('info', info)
      if (info && info['nickname'] && info['nickname'] !== 'Новый чат') {
        text = text.replace('[ник]', info['nickname'])
      } else {
        text = no_format
      }
      this.$parent.useTemplate(text)
    },
    // eslint-disable-next-line no-unused-vars
    deleteTemplate(id) {
      this.showDialogDelete = true
      this.idForDelete = id
    },
    backTemplateToList() {
      this.showDialogCreate = false
    },
    createTemplate() {
      this.nameTemplate = ''
      this.bodyTemplate = '[ник]'
      this.bodyTemplateNoF = ''
      this.showDialogCreate = true
    },
    createdTemplate() {
      this.$store.dispatch('createTemplate', {
        'name': this.nameTemplate,
        'body_messages': this.bodyTemplate,
        'body_messages_no_format': this.bodyTemplateNoF,
      })

      this.backTemplateToList()
    },
    getInfoTemplate(id) {
      this.idForDelete = id
      let data = this.$store.getters.getOneTemplate(id)

      this.nameTemplate = data.name
      this.bodyTemplate = data.body_messages
      this.bodyTemplateNoF = data.body_messages_no_format

      this.showDialogCreate = true

      this.edit = true
    },
    sendEditTemplate() {
      this.$store.dispatch('editTemplate', {
        'id': this.idForDelete,
        'name': this.nameTemplate,
        'body_messages': this.bodyTemplate,
        'body_messages_no_format': this.bodyTemplateNoF
      })

      this.backTemplateToList()
    },
    controlSend() {
      if (this.edit) {
        console.log('sendEditTemplate')
        this.sendEditTemplate()
        this.edit = false
        this.idForDelete = undefined
      } else {
        console.log('createdTemplate')
        this.createdTemplate()
      }
    }
  }
}
</script>

<style scoped>

</style>