import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import user from "@/store/modules/user";
import chat from "@/store/modules/chat";
import status from "@/store/modules/status";
import messages from "@/store/modules/messages";
import template from "@/store/modules/template";
import modal from "./modules/modal";
import forwardModal from "./modules/forwardModal";

Vue.use(Vuex);


export const store = new Vuex.Store({
    modules: {
        auth,
        user,
        chat,
        status,
        messages,
        modal,
        template,
        forwardModal
    }
})

export default store;
