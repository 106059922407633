<template>
  <div class="mb-3">
    <div style="padding-bottom: 30px" class="mb-3 border-b shadow-bot">
      <div class="flex items-center w-full px-3 mt-6">
        <div class="px-2 text-gray-500 rounded-full hover:text-gray-600">
          <svg class="w-6 h-6 text-gray-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24">
            <path fill-rule="nonzero"
                  d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,11 C12.5128358,11 12.9355072,11.3860402 12.9932723,11.8833789 L13,12 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4871642,18 11.0644928,17.6139598 11.0067277,17.1166211 L11,17 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M12,6.5 C12.8284271,6.5 13.5,7.17157288 13.5,8 C13.5,8.82842712 12.8284271,9.5 12,9.5 C11.1715729,9.5 10.5,8.82842712 10.5,8 C10.5,7.17157288 11.1715729,6.5 12,6.5 Z"></path>
          </svg>
        </div>

        <div class="ml-4">
          <div class="inline-block relative w-64">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   for="infoform-name">
              Никнейм
            </label>
            <input id="infoform-name" :value="chatInfo.nickname"
                   @change="updateUserInfo('nickname', $event.target.value)"
                   class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded"
                   type="text">
          </div>
        </div>
      </div>
      <div class="flex items-center w-full px-3 mt-2">
        <div class="px-2 text-gray-500 rounded-full hover:text-gray-600">
          <svg class="w-6 h-6 text-gray-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24">
            <path fill-rule="nonzero"
                  d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,11 C12.5128358,11 12.9355072,11.3860402 12.9932723,11.8833789 L13,12 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4871642,18 11.0644928,17.6139598 11.0067277,17.1166211 L11,17 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M12,6.5 C12.8284271,6.5 13.5,7.17157288 13.5,8 C13.5,8.82842712 12.8284271,9.5 12,9.5 C11.1715729,9.5 10.5,8.82842712 10.5,8 C10.5,7.17157288 11.1715729,6.5 12,6.5 Z"></path>
          </svg>
        </div>

        <div class="ml-4">
          <div class="inline-block relative w-64">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   for="infoform-description">
              Заявка
            </label>
            <input @change="updateUserInfo('description', $event.target.value)" id="infoform-description"
                   :value="chatInfo.description"
                   class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded"
                   type="text">
          </div>
        </div>
      </div>


      <div class="flex items-center w-full px-3 mt-2">
        <div class="px-2 text-gray-500 rounded-full hover:text-gray-600">
          <svg class="w-6 h-6 text-gray-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24">
            <path fill-rule="nonzero"
                  d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,11 C12.5128358,11 12.9355072,11.3860402 12.9932723,11.8833789 L13,12 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4871642,18 11.0644928,17.6139598 11.0067277,17.1166211 L11,17 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M12,6.5 C12.8284271,6.5 13.5,7.17157288 13.5,8 C13.5,8.82842712 12.8284271,9.5 12,9.5 C11.1715729,9.5 10.5,8.82842712 10.5,8 C10.5,7.17157288 11.1715729,6.5 12,6.5 Z"></path>
          </svg>
        </div>

        <div class="ml-4">
          <div class="inline-block relative w-64">
            <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                   for="infoform-note">
              Примечание
            </label>
            <textarea @change="updateUserInfo('note', $event.target.value)" id="infoform-note"
                      :value="chatInfo.note"
                      class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded"
                      type="text"></textarea>
          </div>
        </div>
      </div>

      <div class="flex items-center w-full px-3 mt-2">
        <div class="px-2 text-gray-500 rounded-full hover:text-gray-600">
          <svg class="w-6 h-6 text-gray-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24"
               height="24" viewBox="0 0 24 24">
            <path fill-rule="nonzero"
                  d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 Z M12,11 C12.5128358,11 12.9355072,11.3860402 12.9932723,11.8833789 L13,12 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4871642,18 11.0644928,17.6139598 11.0067277,17.1166211 L11,17 L11,12 C11,11.4477153 11.4477153,11 12,11 Z M12,6.5 C12.8284271,6.5 13.5,7.17157288 13.5,8 C13.5,8.82842712 12.8284271,9.5 12,9.5 C11.1715729,9.5 10.5,8.82842712 10.5,8 C10.5,7.17157288 11.1715729,6.5 12,6.5 Z"></path>
          </svg>
        </div>
        <div class="ml-4">
          <div class="mb-1 mr-auto text-sm font-semibold leading-none text-gray-600">Статус</div>
          <div class="inline-block relative w-64">
            <select @change="updateUserInfo('status', $event.target.value)" v-model="chatInfo.status"
                    class="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded">
              <option v-for="status in statusList" :key="status.id" :style="{'color': status.color_status}">
                <a> {{ status.name_status }}</a>
              </option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4 mt-4 d-flex" style="gap: 8px;" >
        <input @change="changeArchiveStatus" type="checkbox" v-model="isArchive">
        <span style="font-size: 12px; font-weight: bolder; color: rgb(74,85,104); text-transform: uppercase;">
          Архивный чат
        </span>
      </div>
      <div class="flex content-center items-center w-full px-3 mt-6">
        <button style="flex: auto;"
                class="justify-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-transparent rounded">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "infoForm",
  data() {
    let chat = this.$store.getters.getInfoChat(this.$store.getters.getSelectChat.chat_id);
    return {
      statusList: this.$store.getters.getStatusList,
      nickname: '',
      isArchive: !chat.active,
    }
  },
  methods: {
    changeArchiveStatus() {
      let instance = this.$store.getters.getSelectChat.instance;
      let chat = this.$store.getters.getSelectChat.chat_id;
      axios.post(process.env.VUE_APP_URL + 'api/v2/changeChatActiveStatus/' + `${instance}/${chat}`, {
        new_status: !this.isArchive
      })

      this.$store.commit('resetChatListBase')
      this.$store.commit('resetChatListSearch')
      this.$store.dispatch('getChatsBase', {
          instance: this.$store.getters.getSelectInstance,
          status: this.$store.getters.getSelectedStatus,
          page: '1'
      })
    },
    updateUserInfo(key, value) {
      let formData = new FormData();

      formData.append('chat_id', this.$store.getters.getSelectChat.chat_id)
      formData.append('instance_id', this.$store.getters.getSelectChat.instance)
      formData.append(key, value)
      formData.append('filter', this.$store.getters.getSelectedStatus)

      axios({
        method: 'post',
        url: process.env.VUE_APP_URL + 'api/v2/ChatInfo',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then(() => {
        this.$store.dispatch('resetChat')
        this.$store.dispatch('getChatsBase', {
          instance: this.$store.getters.getSelectChat.instance,
          status: this.$store.getters.getSelectedStatus,
          page: this.$store.getters.getPaginatorInfoChat.page
        })
      }).catch(response => (console.log('error uploading', response)))
    }
  },
  computed: {
    chatInfo() {
      return this.$store.getters.getInfoChat(this.$store.getters.getPaginatorInfoMessages.chat_id) ? this.$store.getters.getInfoChat(this.$store.getters.getPaginatorInfoMessages.chat_id) : this.$store.getters.getInfoChatChatListSearch(this.$store.getters.getPaginatorInfoMessages.chat_id)
    }
  },
}
</script>

<style scoped>

</style>