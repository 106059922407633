// import axios from "axios";


const state = {
    showMF: false,
    messages_type: '',
    messages: '',
    messages_id: '',
    chat_id: '',
}

const getters = {
    getShowMF(state) {
        return state.showMF
    },
    getForwardMessagesType(state) {
        return state.messages_type
    },
    getForwardMessages(state) {
        return state.messages
    },
    getForwardMessagesID(state) {
        return state.messages_id
    },
    getForwardChatID(state) {
        return state.chat_id
    }
}

const mutations = {}

const actions = {
    statusShowMF(context, data) {
        context.state.showMF = data.show
        context.state.messages_type = data.messages_type
        context.state.messages = data.messages
        context.state.messages_id = data.messages_id
    }
}

export default {
    state, getters, mutations, actions
}
