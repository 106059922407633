const state = {
    modalShowIMG: false,
    modalShowInfo: false,
    modalShowBaseIMG: false,
}

const getters = {
    getModalShowIMG(state) {
        return state.modalShowIMG
    },
    getModalShowInfo(state) {
        return state.modalShowInfo
    },
    modalShowBaseIMG(state) {
        return state.modalShowBaseIMG
    }
}

const mutations = {
    setModalShowIMG(state, status) {
        return state.modalShowIMG = status
    },
    setModalShowInfo(state, status) {
        return state.modalShowInfo = status
    },
    setModalShowBaseIMG(state, status) {
        return state.modalShowBaseIMG = status
    },
}
export default {
    state, getters, mutations
}
