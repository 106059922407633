<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" style="z-index: 94 !important;" v-if="$store.getters.getShowMF">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>


      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline" style="margin-bottom: 25%;">
        <div class="bg-white px-4 pt-2 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">


            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h2 style="font-size: 24px;" class="text-xl leading-6 font-medium text-gray-900" id="modal-headline">
                Переслать сообщения
              </h2>

              <div class="mt-3 mb-3">
                <p class="text-sm text-gray-500" style="background: #90ff90;color: black;border-radius: 5px;">
                  <template v-if="$store.getters.getForwardMessagesType ==='chat'">
                    <a v-html="$store.getters.getForwardMessages"></a>
                  </template>
                </p>
              </div>


              <button @click="init()"
                      class="justify-center bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-1 px-1 pr-1 ml-2 mr-2  border hover:border-transparent rounded"
                      style="flex:1 1 auto; font-size: 12px; width: 10%;">
                Сброс
              </button>
              <input type="search" v-model="textS"
                     class="py-2 pl-3 text-sm text-white bg-gray-200 border border-transparent appearance-none rounded-tg focus:bg-white focus:outline-none focus:border-blue-500 focus:text-gray-900 focus:shadow-outline-blue"
                     style="border-radius: 25px; color: black !important; width: 45%;"
                     placeholder="Поиск..." autocomplete="off" @keydown.enter="serch">

              <button @click="serch"
                      class="justify-center bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-1 px-1 pr-1 ml-2 mr-2  border hover:border-transparent rounded"
                      style="flex:1 1 auto; font-size: 12px; width: 10%;">
                Поиск
              </button>

              <h2 class="text-xl leading-6">
                Чаты:
              </h2>

              <div style="text-align: left;height: 300px;overflow: auto;" class="mt-3 mb-3">
                <chat v-for="chat in selectObjChat? [selectObjChat]: list"
                      :key="$store.getters.getSelectInstance + '_' + chat.id" :id="chat.chat_id + 'm'"
                      :ChatID="chat.chat_id"
                      :avatar="chat.avatar"
                      :name-contact="chat.name" :nick-name="chat.nickname" :short-description="chat.description"
                      :data="String(chat.last_time_update_chat)"
                      :read-chat="true" :status="chat.status" :phoneContact="chat.format_phone"
                      :selectNew="chat.selectNew" :method="'selectChatForwardMessage'"></chat>
              </div>
              <button type="button" @click="sendForwardMessage()"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Отправить
              </button>
              <button type="button" @click="cancelForwardMessage()"
                      class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Отмена
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chat from "@/components/static/chat";
import axios from "axios";


export default {
  name: "forwardModal",
  components: {Chat},
  data() {
    return {
      list: [],
      select_chat_id: null,
      select_instance_id: null,
      selectObjChat: null,
      textS: ''
    }
  },
  methods: {
    cancelForwardMessage() {
      this.$store.dispatch('statusShowMF', {'show': false, 'messages_type': null, 'messages': null})
    },
    serch() {
      axios({
        method: 'get',
        url: process.env.VUE_APP_URL + `api/v2/searchChat/${this.$store.getters.getSelectInstance}/${this.textS}?type=chat`,
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(result => {
        this.list = result.data.chat
      })
    },
    selectChatForwardMessage(instance_id, chat_id) {
      this.select_chat_id = chat_id
      this.select_instance_id = instance_id

      this.selectObjChat = this.list.find(chat => chat.chat_id === chat.chat_id)

      document.getElementById(chat_id + 'm').classList.add('selectChat')

    },
    sendForwardMessage() {
      let data = {
        type: 'messages.send',
        chat_id: this.select_chat_id,
        message: '',
        forward_id: this.$store.getters.getForwardMessagesID,
        instance_id: this.select_instance_id,
        operator_name: this.$store.getters.getUserUsername,
      }

      this.$store.getters.getCurrentWS.send(JSON.stringify(data));

      this.cancelForwardMessage()
    },
    init() {
      axios({
        method: 'get',
        url: process.env.VUE_APP_URL + `api/v2/getChats/${this.$store.getters.getSelectInstance}/all/${this.$store.getters.getPaginatorInfoChat.page}`,
        headers: {'Content-Type': 'multipart/form-data'}
      }).then(result => {
        this.list = result['data']['content']
      });
      console.log('got chats:', this.list);
    }
  },
  computed: {
    getChatList() {
      if (this.$store.getters.getChatListSearch.chat.length > 0) {
        return this.$store.getters.getChatListSearch.chat
      } else {
        return this.list
      }
    }
  },
  created() {
    this.init()
  }

}
</script>

<style scoped>

</style>