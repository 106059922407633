<template>
  <div>
    <!-- scrollButton -->
    <div class="fixed absolute bottom-0 right-0 z-10"><a style="background: #4299e1; margin-bottom: 85%;"
                                                         @click="scrollButtonUp()"
                                                         class="flex items-center justify-center w-12 h-12 mr-3 text-xl font-semibold text-white bg-blue-500 rounded-full focus:outline-none flex-no-shrink">
      <svg style="transform: rotate(180deg);" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" width="19"
           height="20">
        <path fill="currentColor" d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"></path>
      </svg>
    </a></div>

    <!-- scrollButton -->
    <div class="fixed absolute bottom-0 right-0 z-10 mb-0"><a style="background: #4299e1"
                                                              @click="scrollButtonDown()"
                                                              class="flex items-center justify-center w-12 h-12 mr-3 text-xl font-semibold text-white bg-blue-500 rounded-full focus:outline-none flex-no-shrink">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" width="19" height="20">
        <path fill="currentColor" d="M3.8 6.7l5.7 5.7 5.7-5.7 1.6 1.6-7.3 7.2-7.3-7.2 1.6-1.6z"></path>
      </svg>
    </a></div>
  </div>
</template>

<script>
export default {
  name: "scroll",
  methods: {
    scrollButtonUp() {
      document.getElementById('chat-box').scrollIntoView({block: 'start'});
    },
    scrollButtonDown() {
      document.getElementById('chat-box').scrollIntoView(false);
    }
  }
}
</script>

<style scoped>

</style>