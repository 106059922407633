<template>
  <div class="z-20 flex flex-grow-0 flex-shrink-0 w-full pr-3 bg-white border-b justify-content-between align-items-center">

    <img @click="$store.commit('setModalShowIMG', true)"
         :src="getAvatar(chatInfo.avatar)"
         alt=""
         class="object-cover w-12 h-12 w-12 h-12 mx-4 my-2 bg-blue-500 bg-center bg-no-repeat bg-cover rounded-full cursor-pointer">

    <modal v-if="$store.getters.getModalShowIMG"
           :img="getAvatar(chatInfo.avatar)"
           :close-name="'setModalShowIMG'"
           btn-left="Закрыть"></modal>

    <div class="flex flex-col justify-center mr-2 overflow-hidden cursor-pointer">
      <span style="font-size: 12px; line-height: 1;">
        <span class="font-medium text-gray-600">
          Имя в контактах:
        </span>
        {{ chatInfo.name }}
      </span>
      <span style="font-size: 12px; line-height: 1;">
        <span class="font-medium text-gray-600">
          Имя у клиента:
        </span>
          {{ chatInfo.wa_name }}
      </span>
      <span style="font-size: 12px; line-height: 1;">
        <span class="font-medium text-gray-600">
          Никнейм в чате:
        </span>
          {{ chatInfo.nickname }}
      </span>
      <span style="font-size: 12px; line-height: 1;">
        <span class="font-medium text-gray-600">
          Статус:
        </span>
        {{ chatInfo.status }}
      </span>
      <span style="font-size: 12px; line-height: 1;">
        <span class="font-medium text-gray-600">
          Последний визит:
        </span>
        {{ lastVisit }}
      </span>
    </div>

    <div class="flex flex-col justify-center overflow-hidden text-right">
      <div class="overflow-hidden text-sm font-medium leading-tight text-gray-600 whitespace-no-wrap">
        <a>{{ chatInfo.format_phone }}</a>
      </div>

      <div class="overflow-hidden text-sm font-medium leading-tight text-gray-600 whitespace-no-wrap">
        <a>{{ chatInfo.phone }}</a>

      </div>

      <div class="overflow-hidden text-sm font-medium leading-tight text-gray-600 whitespace-no-wrap">
        <a>Заявка: {{ chatInfo.description }}</a>
      </div>
    </div>

    <div class="flex flex-col justify-center flex-1 overflow-hidden cursor-pointer">
      <div class="overflow-hidden text-sm font-medium leading-tight text-gray-600 whitespace-no-wrap">
        <a @click="$copyText(chatInfo.format_phone);"
           style="background: rgb(15 123 60 / 74%);color: white;font-size: 12px; margin: 1%; padding: 1%; cursor: pointer">CP</a>
        <a :href="$store.getters.getUserInstanceSelect.link1 ? $store.getters.getUserInstanceSelect.link1.replace('%content%', crmFormatPhone(this.chatInfo.format_phone)): ''"
           target="_blank"
           style="background: rgb(247, 118, 118);color: white;font-size: 12px; margin: 1%; padding: 1%; cursor: pointer">CRM</a>
      </div>

      <div class="overflow-hidden text-sm font-medium leading-tight text-gray-600 whitespace-no-wrap">
        <a @click="$copyText(chatInfo.phone);"
           style="background: rgb(15 123 60 / 74%);color: white;font-size: 12px; margin: 1%; padding: 1%; cursor: pointer">CP</a>
        <a :href="$store.getters.getUserInstanceSelect.link2 ? $store.getters.getUserInstanceSelect.link2.replace('%content%', this.chatInfo.phone) : ''"
           target="_blank"
           style="background: rgb(247, 118, 118);color: white;font-size: 12px; margin: 1%; padding: 1%; cursor: pointer">CRM</a>
      </div>

      <div class="overflow-hidden text-sm font-medium leading-tight text-gray-600 whitespace-no-wrap">
        <a @click="$copyText(chatInfo.description);"
           style="background: rgb(15 123 60 / 74%);color: white;font-size: 12px; margin: 1%; padding: 1%; cursor: pointer">CP</a>
        <a :href="this.chatInfo.description? $store.getters.getUserInstanceSelect.link3 ? $store.getters.getUserInstanceSelect.link3.replace('%content%', this.chatInfo.description) : '' : '#'"
           target="_blank"
           style="background: rgb(247, 118, 118);color: white;font-size: 12px; margin: 1%; padding: 1%; cursor: pointer">CRM</a>
      </div>
    </div>

    <div class="flex flex-col justify-center flex-2 overflow-hidden">
      <div class="overflow-hidden text-sm font-medium leading-tight text-gray-600">
        <a>Примечание: {{ chatInfo.note }} </a>
      </div>
    </div>
    <button @click="$store.dispatch('unReadChat'); lockUnRead = false" :disabled="!lockUnRead"
            class="flex self-center p-2 ml-2 text-gray-500 rounded-full focus:outline-none hover:text-gray-600 hover:bg-gray-300">
      <svg class="w-6 h-6 text-gray-600 fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
           viewBox="0 0 24 24">
        <path fill-rule="nonzero"
              d="M11,20 L13,20 C13.5522847,20 14,20.4477153 14,21 C14,21.5128358 13.6139598,21.9355072 13.1166211,21.9932723 L13,22 L11,22 C10.4477153,22 10,21.5522847 10,21 C10,20.4871642 10.3860402,20.0644928 10.8833789,20.0067277 L11,20 L13,20 L11,20 Z M3.30352462,2.28241931 C3.6693482,1.92735525 4.23692991,1.908094 4.62462533,2.21893936 L4.71758069,2.30352462 L21.2175807,19.3035246 C21.6022334,19.6998335 21.5927842,20.332928 21.1964754,20.7175807 C20.8306518,21.0726447 20.2630701,21.091906 19.8753747,20.7810606 L19.7824193,20.6964754 L18.127874,18.9919007 L18,18.9999993 L4,18.9999993 C3.23933773,18.9999993 2.77101468,18.1926118 3.11084891,17.5416503 L3.16794971,17.4452998 L5,14.6972244 L5,8.9999993 C5,7.98873702 5.21529462,7.00715088 5.62359521,6.10821117 L3.28241931,3.69647538 C2.89776658,3.3001665 2.90721575,2.66707204 3.30352462,2.28241931 Z M7.00817933,8.71121787 L7,9 L7,14.6972244 C7,15.0356672 6.91413188,15.3676193 6.75167088,15.6624466 L6.66410059,15.8066248 L5.86851709,17 L16.1953186,17 L7.16961011,7.7028948 C7.08210009,8.02986218 7.02771758,8.36725335 7.00817933,8.71121787 Z M12,2 C15.7854517,2 18.8690987,5.00478338 18.995941,8.75935025 L19,9 L19,12 C19,12.5522847 18.5522847,13 18,13 C17.4871642,13 17.0644928,12.6139598 17.0067277,12.1166211 L17,12 L17,9 C17,6.23857625 14.7614237,4 12,4 C11.3902636,4 10.7970241,4.10872043 10.239851,4.31831953 C9.72293204,4.51277572 9.14624852,4.25136798 8.95179232,3.734449 C8.75733613,3.21753002 9.01874387,2.6408465 9.53566285,2.4463903 C10.3171048,2.15242503 11.1488212,2 12,2 Z"/>
      </svg>
    </button>

    <button
        @click="showAttachments = true;"
        style="flex-shrink: 0;"
        class="flex self-center p-2 ml-2 text-gray-500 rounded-full focus:outline-none hover:text-gray-600 hover:bg-gray-300"
    >
      <img
          src="/svg/clip.png" alt="Вложения"
          style="width: 24px; height: 24px; opacity: 0.8;"
      >
    </button>
    <attachments v-if="showAttachments" @closeWindow="showAttachments = false" />
    <button
        :class="{'button-selected': isChatPinned}"
        @click="changeChatPinStatus()"
        style="flex-shrink: 0;"
        class="flex self-center p-2 ml-2 text-gray-500 rounded-full focus:outline-none hover:text-gray-600 hover:bg-gray-300"
    >
      <img src="/svg/pin.png" alt="Закрепить" style="width: 24px; height: 24px; opacity: 0.8;">
    </button>
    <button
        v-if="metadata_parsed && metadata_parsed.isGroup"
        @click="showParticipants = !showParticipants"
        class="flex self-center p-2 ml-2 text-gray-500 rounded-full focus:outline-none hover:text-gray-600 hover:bg-gray-300">
      <img src="/svg/group_participants.svg" style="width: 24px; height: 24px; color: rgba(0,0,0,0.1)" alt="Участники">
    </button>
    <div v-if="showParticipants"
         style="width: 400px; height: 300px; background: white; position: fixed; top: 17%!important;left: 50%!important; overflow: scroll;">
      <div style="width: 100%; text-align: center">
        <a style="text-align: center; padding: 10px; font-weight: bolder;">Участники группы</a>
        <a @click="showParticipants = !showParticipants" style="text-align: right">X</a>
      </div>
      <div style="font-size: 24px; display: flex; flex-direction: column; margin-left: 16px">
        <span
            v-for="(participant, key) in metadata_parsed.participants"
            :key="key"
        >
          {{ participant.id.split('@')[0] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../other/modal";
import {formatTimestamp} from "../../util/main";
import attachments from "./attachments";
import axios from "axios";

export default {
  name: "navPanel",
  components: {Modal, attachments},
  props: {
    newChatId: {type: String,  required: false,  default: null},
  },
  data() {
    return {
      lockUnRead: true,
      url: process.env.VUE_APP_URL,
      showParticipants: false,
      showAttachments: false,
    }
  },
  computed: {
    chatInfo() {
      console.log('looking for chat info');
      const paginatorInfoMessages = this.$store.getters.getPaginatorInfoMessages;
      const chatId = this.newChatId;
      const infoChatByPaginatorInfoMessages = this.$store.getters.getInfoChat(chatId);
      const infoChatByChatListSearchWith = this.$store.getters.getInfoChatChatListSearch(chatId);
      console.log('paginatorInfoMessages:', paginatorInfoMessages);
      console.log('chatId:', chatId);
      console.log('infoChatByPaginatorInfoMessages:', infoChatByPaginatorInfoMessages);
      console.log('infoChatByChatListSearchWith', infoChatByChatListSearchWith);
      return this.$store.getters.getInfoChat(chatId)
        ?? this.$store.getters.getInfoChatChatListSearch(chatId)
    },
    lastVisit() {
      return formatTimestamp(this.chatInfo.last_visit);
    },
    metadata_parsed() {
      let json;
      try {
          json = JSON.parse(this.chatInfo.metadata)
      } catch (e) {
          json = null
      }
      return json
    },
    isChatPinned() {
      return this.chatInfo.is_pinned;
    }
  },
  methods: {
    changeChatPinStatus() {
      let instance = this.$store.getters.getSelectChat.instance;
      let chat = this.$store.getters.getSelectChat.chat_id;
      let pinned = this.chatInfo.is_pinned || false;
      axios.post(process.env.VUE_APP_URL + 'api/v2/changeChatPinnedStatus/' + `${instance}/${chat}`, {
        is_pinned: !pinned,
      })
      this.chatInfo.is_pinned = !pinned;
    },
    crmFormatPhone(phone) {
      const phoneList = phone.split('-')
      phoneList.shift()
      return phoneList.join('-')
    },
    getAvatar(avatar_data) {
      let avatar = null
      if (avatar_data.startsWith('https://pps.whatsapp.net')) {
        avatar = avatar_data
      }
      else if (
          avatar_data &&
          avatar_data !== '/profile-placeholder.jpg' &&
          !avatar_data.startsWith('https://pps.whatsapp.net') &&
          avatar_data !== 'null'
      ) {
        avatar = this.url + avatar_data
      } else {
        avatar = './profile-placeholder.jpg'
      }

      return avatar
    }
  },
  mounted() {
    this.lockUnRead = true

    console.log('chatInfo', this.chatInfo)

    let title = ''

    if (this.chatInfo?.description) {
      title += this.chatInfo.description + ' ';
    }

    if (this.chatInfo?.nickname) {
      title += this.chatInfo.nickname + ' ';
    }

    if (this.chatInfo?.format_phone) {
      title += this.chatInfo.format_phone + ' ';
    }

    document.title = title;
  }
}
</script>

<style scoped>
  .button-selected {
    background-color: #afe8bf!important;
  }
</style>