<template>
  <span style="cursor: pointer;" class="span-label mb-1"
        :style="{ 'background': background, 'color': textColor, 'min-width': minWidth ? minWidth + 'px' : 'auto'}">{{ text }}</span>
</template>

<script>
export default {
  name: "tag",
  props: {
    background: String,
    textColor: String,
    text: String,
    minWidth: Number
  }
}
</script>