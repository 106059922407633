<template>
  <div class="shadow-bot">
    <a @click="setFilter('all')">
      <tag id="status-all"
           :text="`Все (${allChatsCounter})`"
           class="tagCategory font-bold"
      ></tag>
    </a>

    <a @click="setFilter('noread')">
      <tag id="status-noread"
           :text="`Непрочитанные (${unreadChatsCounter})`"
           class="tagCategory font-bold"
      ></tag>
    </a>

    <a @click="setFilter('noask')">
      <tag id="status-noask"
           :text="`Неотвеченные (${unansweredChatsCounter})`"
           class="tagCategory font-bold"
      ></tag>
    </a>

    <a @click="setFilter('archive')">
      <tag id="status-archive"
           :text="`Архив (${archivedChatsCounter})`"
           class="tagCategory font-bold"
      ></tag>
    </a>

    <a v-for="status in $store.getters.getStatusList" :key="status.id" @click="setFilter(status.id)">
      <tag :id="`status-${status.id}`" :text="status.name_status" class="tagCategory"></tag>
    </a>
  </div>
</template>

<script>
import Tag from "@/components/static/tag";
import {cssSelectRemove} from "@/util/main";

export default {
  name: "category",
  components: {Tag},
  data() {
    return {
      oldInstance: null,
    }
  },
  computed: {
    unreadChatsCounter() {
      const unreadChatsCounter = this.$store.getters.getPaginatorInfoChat?.unread_chats_count;
      console.log('unreadChatsCounter:', unreadChatsCounter)
      return Number.isInteger(unreadChatsCounter) ? unreadChatsCounter : '...';
    },
    allChatsCounter() {
      const allChatsCounter = this.$store.getters.getPaginatorInfoChat?.all_chats_count;
      console.log('allChatsCounter:', allChatsCounter);
      return Number.isInteger(allChatsCounter) ? allChatsCounter: '...';
    },
    archivedChatsCounter() {
      const archivedChatsCounter = this.$store.getters.getPaginatorInfoChat?.archived_chats_count;
      console.log('archivedChatsCounter:', archivedChatsCounter);
      return Number.isInteger(archivedChatsCounter) ? archivedChatsCounter : '...';
    },
    unansweredChatsCounter() {
      const unansweredChatsCounter = this.$store.getters.getPaginatorInfoChat?.unanswerred_chats_count;
      console.log('unansweredChatsCounter:', unansweredChatsCounter);
      return Number.isInteger(unansweredChatsCounter) ? unansweredChatsCounter : '...';
    }
  },
  methods: {
    setFilter: function (id) {
      console.log('in setFilter with id:', id);
      this.$store.dispatch('resetChat');
      console.log('reset of chat done');
      cssSelectRemove('selectCategory');
      document.getElementById(`status-${id}`).classList.add('selectCategory')
      this.$store.dispatch('editSelectStatus', id)

      setTimeout(() => {
        if (document.getElementById(this.$store.getters.getSelectChat.chat_id) && this.$store.getters.getSelectChat.instance === this.oldInstance) {
          document.getElementById(this.$store.getters.getSelectChat.chat_id).classList.add('selectChat')
          this.oldInstance = this.$store.getters.getSelectChat.instance
        } else {
          this.oldInstance = this.$store.getters.getSelectChat.instance
        }
      }, 450)
    }
  },
  mounted() {
    document.getElementsByClassName('tagCategory')[0].classList.add('selectCategory')

    this.$store.dispatch('getStatus')
  }
}
</script>

<style scoped>
.selectCategory {
  background: #bee3f8;
}

</style>