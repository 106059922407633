<template>
  <div class="d-flex justify-content-around" style="gap: 8px; flex-wrap: wrap;">
    <div v-for="(image, key) in data" :key="key" style="width: 150px; height: 150px;">
      <img
          :src="buildMediaUrl(image)" alt="Картинка"
          style="width: 100%; height: 100%; object-fit: cover; cursor: pointer; box-shadow: rgb(0 0 0 / 50%) 2px 2px 5px;"
          @click="fullImage = buildMediaUrl(image);"
      >
    </div>
    <image_full v-if="fullImage" @closeButtonPressed="fullImage = '';" :src="fullImage" />
  </div>
</template>

<script>
import attachments_mixin from "../../../util/attachments_mixin";
import image_full from "../../image_full";
export default {
  name: "images",
  components: {image_full},
  mixins: [attachments_mixin],
  data() {
    return {
      message_type: 'image',
      data: [],
      fullImage: '',
    }
  },
}
</script>

<style scoped>

</style>