<template>
  <div class="relative flex flex-wrap antialiased bg-white border-b">
    <div class="border-r border-gray-300 w-1/12 text-center pt-2" style="padding-left: 1% !important;">
      <a style="font-size: 11px; color: #718096">Сотрудник</a>
      <h3 style="font-size: 17px; text-transform:capitalize;"><a href="/">{{ $store.getters.getUserFirstName }}</a></h3>
    </div>


    <div class="border-r border-gray-300 w-10/12 text-center flex-no-wrap"
         style=" display: inline-flex; 	min-height: 0; flex: 1; overflow-x: auto; overflow-y: hidden">
      <a v-for="instance in $store.getters.getUserInstance" :key="instance.id"
         @click="setInstance(instance.phone, 'click')">
        <instance :read_instance="instance.read_instance" :ind="instance.id" :id="instance.phone"
                  :name-instance="instance.name" :phone-instance="instance.phone"
                  :last-status="instance.last_status" :paid_till="instance.formater_paid_till"
                   :stopped="instance.stopped" :battery="instance.battery_charge"
                  :has-unread-messages="instance.has_unread_messages"
                  :last-updated="$store.getters.getLastDateUpdatedInstance"
                  :token-account="instance.token_account"
        ></instance>
      </a>
    </div>


    <div class="border-r border-gray-300 w-1/12 text-center pt-4">
      <a @click="logout" href="#"><h1 style="font-weight: 500;color: #007de4;">Выйти</h1></a>
    </div>
  </div>
</template>

<script>
import Instance from "@/components/static/instance";
import {cssSelectRemove} from "@/util/main";

export default {
  name: "tools",
  components: {Instance},
  methods: {
    setInstance: function (phone_instance, target = null) {
      console.log('Setting instance with phone:', phone_instance);
      if (target !== 'outside' || target === 'withUrlParams') {
        console.log('going to reset the chat. Condition target !== outside is', target !== 'outside', 'and target === withUrlParams is', target === 'withUrlParams');
        this.$store.dispatch('resetChat');
      }
      if (this.$store.getters.getSelectInstance !== phone_instance || target === 'click') {
        console.log('changing Instance');
        this.$emit('changeInstance');
      }
      console.log('Going to remove css select Instance');
      cssSelectRemove('selectInstance')
      console.log('removed css select instance');
      this.$nextTick(() => {
        const instanceElement = document.getElementById(phone_instance);
        console.log('Instance element:', instanceElement, 'Found by phone:', phone_instance);
        instanceElement?.classList?.add('selectInstance');
      console.log('added selectInstance class');
      cssSelectRemove('selectCategory')
      console.log('removed select Category');
      document.getElementById(`status-all`)?.classList?.add('selectCategory')
      console.log('added selected category to status-all element');
      })

      this.$store.dispatch('setSelectInstance', phone_instance).then(() => {
        console.log('Instance set with number:', phone_instance)
      });
    },
    logout() {
      this.$router.push('/auth');
      this.$store.dispatch('logout');
    },
    init() {
      this.$store.dispatch('getUserInfo');
    }
  },
  computed: {},
  mounted() {
    this.init();
  }
}
</script>

<style scoped>
.selectInstance {
  background: #bee3f8;
}
</style>