<template>
  <li :class="[selectNew ? 'selectNew' : '']"
      @click="$parent[method]($store.getters.getSelectInstance, ChatID, start_number_messages)"
      class="flex flex-no-wrap items-center pr-3 text-black rounded-lg cursor-pointer mt-200 py-65 hover:bg-gray-200 frontbildtitle">
    <div class="flex justify-between w-full focus:outline-none">
      <div class="flex justify-between w-full">
        <div>
          <div
              class="relative flex items-center justify-center w-12 h-12 ml-2 mr-3 text-xl font-semibold text-white bg-blue-500 rounded-full flex-no-shrink">
            <img class="object-cover w-12 h-12 rounded-full"
                 :src="getAvatar"
                 alt="">
          </div>


          <a style="padding-left: 10%;" v-if="shortDescription">{{ shortDescription }}</a>
        </div>


        <div class="items-center flex-1 min-w-0">
          <div class="flex justify-between mb-1">
            <h2 style="font-size: 15px;" class="text-sm font-semibold text-black">

              <a style="font-size: 17px">
                <a v-if="metadata_parsed.isGroup" style="color: darkred; font-weight: bold">[Группа]</a>
                <a v-if="status" :style="{ 'color': color(status) }">[{{ status }}] </a>
                <a v-if="nickName">{{ nickName }}</a>
                <a v-else>{{ nameContact }}</a>
              </a>
              <br>
              <a style="font-weight: 400;" @click="$parent.clickCopy(phoneContact)">{{ nameContact }} | {{
                  phoneContact
                }}</a>
            </h2>
            <div class="flex">
              <div class="d-flex flex-column" style="width: fit-content; flex-shrink: 0;">
                <svg v-if="readLastMessage" class="w-4 h-4 text-green-500 fill-current" xmlns="http://www.w3.org/2000/svg"
                   width="19" style="color: rgb(78, 153, 226);"
                   height="14" viewBox="0 0 19 14">
                <path fill-rule="nonzero"
                      d="M4.96833846,10.0490996 L11.5108251,2.571972 C11.7472185,2.30180819 12.1578642,2.27443181 12.428028,2.51082515 C12.6711754,2.72357915 12.717665,3.07747757 12.5522007,3.34307913 L12.4891749,3.428028 L5.48917485,11.428028 C5.2663359,11.6827011 4.89144111,11.7199091 4.62486888,11.5309823 L4.54038059,11.4596194 L1.54038059,8.45961941 C1.2865398,8.20577862 1.2865398,7.79422138 1.54038059,7.54038059 C1.7688373,7.31192388 2.12504434,7.28907821 2.37905111,7.47184358 L2.45961941,7.54038059 L4.96833846,10.0490996 L11.5108251,2.571972 L4.96833846,10.0490996 Z M9.96833846,10.0490996 L16.5108251,2.571972 C16.7472185,2.30180819 17.1578642,2.27443181 17.428028,2.51082515 C17.6711754,2.72357915 17.717665,3.07747757 17.5522007,3.34307913 L17.4891749,3.428028 L10.4891749,11.428028 C10.2663359,11.6827011 9.89144111,11.7199091 9.62486888,11.5309823 L9.54038059,11.4596194 L8.54038059,10.4596194 C8.2865398,10.2057786 8.2865398,9.79422138 8.54038059,9.54038059 C8.7688373,9.31192388 9.12504434,9.28907821 9.37905111,9.47184358 L9.45961941,9.54038059 L9.96833846,10.0490996 L16.5108251,2.571972 L9.96833846,10.0490996 Z"/>
                </svg>
                <img v-if="isPinned" src="/svg/pin.png" alt="Закрепить" style="width: 16px; height: 16px; opacity: 0.8;">
              </div>
              <span class="ml-1 text-xs font-medium text-gray-600"
                    :class="{ colorDataToday: getDataColorToday(last_message),  colorDataYesterday: getDataColorYesterday(last_message)}">
                {{ lastMessageDate(last_message) }}
              </span>
            </div>
          </div>
          <div class="flex justify-between text-sm leading-none truncate" style="white-space: inherit; !important;">
            <span v-if="lastMessage && lastMessage.startsWith('BEGIN:VCARD')"
                  style="font-size: 15px; color: rgb(255,0,0)"
                  :style="{color: fromMe ? 'rgb(0, 150, 0)' : '#8B0000'}"
            >
              Карточка-контакт
            </span>
            <span v-else style="font-size: 15px; max-height: 2em;"
                  :style="{color: fromMe ? 'rgb(0, 150, 0)' : '#8B0000'}"
                  v-html="lastMessageParsed"
            />
            <span v-if="count_unread > 0"
                  class="flex items-center justify-center w-5 h-5 text-xs text-right text-white bg-green-500 rounded-full">{{
                count_unread
              }}</span>
          </div>
        </div>
      </div>
    </div>


  </li>
</template>

<script>
import {formatTimestamp, getEmojisData, parseTextWithEmojies} from "../../util/main";

export default {
  name: "chat",
  props: {
    ChatID: String,
    nickName: String,
    shortDescription: String,
    nameContact: String,
    phoneContact: String,
    lastMessage: String,
    readLastMessage: Boolean,
    readChat: Boolean,
    data: String,
    avatar: String,
    status: String,
    count_unread: Number,
    last_message: Number,
    selectNew: Boolean,
    method: String,
    fromMe: Boolean,
    metadata: String,
    start_number_messages: Number,
    isPinned: Boolean,
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      emojisData: getEmojisData(),
    }
  },
  methods: {
    color(name) {
      return this.$store.getters.getColorStatusByName(name)
    },
    getDataColorToday(unix_timestamp) {
      const dateNow = new Date(Date.now());
      const messageDate = new Date(unix_timestamp * 1000);

      if (dateNow.getFullYear() !== messageDate.getFullYear()) {
        return false;
      }
      if (dateNow.getMonth() !== messageDate.getMonth()) {
        return false;
      }
      return dateNow.getDate() === messageDate.getDate();
    },
    getDataColorYesterday(unix_timestamp) {
      const yesterdayDate = new Date(Date.now());
      yesterdayDate.setDate(yesterdayDate.getDate() - 1)
      const messageDate = new Date(unix_timestamp * 1000);

      if (yesterdayDate.getFullYear() !== messageDate.getFullYear()) {
        return false;
      }
      if (yesterdayDate.getMonth() !== messageDate.getMonth()) {
        return false;
      }
      return yesterdayDate.getDate() === messageDate.getDate();
    },
    lastMessageDate(unix_timestamp) {
      return formatTimestamp(unix_timestamp)
    }
  },
  computed: {
    lastMessageParsed() {
      if (!this.lastMessage) {
        return '';
      }
      return parseTextWithEmojies(this.lastMessage);
    },
    metadata_parsed() {
      let json;
      try {
          json = JSON.parse(this.metadata)
      } catch (e) {
          json = {}
      }
      return json
    },
    getAvatar() {
      let avatar = null
      if (this.avatar.startsWith('https://pps.whatsapp.net')) {
        avatar = this.avatar
      }
      else if (
          this.avatar &&
          this.avatar !== '/profile-placeholder.jpg' &&
          !this.avatar.startsWith('https://pps.whatsapp.net') &&
          this.avatar !== 'null'
      ) {
        avatar = this.url + this.avatar
      } else {
        avatar = './profile-placeholder.jpg'
      }

      return avatar
    },
  },
}
</script>

<style scoped>
.colorDataToday {
  color: red;
  font-size: 14px;
}

.colorDataYesterday {
  color: #0022ff;
  font-size: 14px;
}

.selectNew {
  background: #ffbaba;
}

.frontbildtitle {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

/*@media screen and (max-width: 1500px) {*/
/*  .frontbildtitle {*/
/*    padding-top: 3rem;*/
/*    padding-bottom: 3rem*/
/*  }*/
/*}*/

/*@media screen and (max-height: 550px) {*/
/*  .frontbildtitle {*/
/*    padding-top: 1.5rem;*/
/*    padding-bottom: 1.5rem*/
/*  }*/
/*}*/
</style>