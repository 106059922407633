import axios from 'axios';

const state = {
    statusList: [],
    selectedStatus: 'all'
}
const getters = {
    getStatusList(state) {
        return state.statusList
    },
    getSelectedStatus(state) {
        return state.selectedStatus
    },
    getColorStatusByName: (state) => (name_status) => {
        let obj = state.statusList.find(status => status.name_status === name_status)
        return obj ? obj.color_status : null
    }
}
const mutations = {
    setStatusList(state, data) {
        state.statusList = data
    },
    setSelectStatus(state, id) {
        return state.selectedStatus = id
    }

}
const actions = {
    getStatus(context) {
        axios({
            method: 'get',
            url: process.env.VUE_APP_URL + `api/v2/getStatus`,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(result => {
            context.commit('setStatusList', result.data)
        })
    },
    editSelectStatus(context, id) {
        context.commit('setSelectStatus', id)

        context.dispatch('getChatsBase', {
            instance: context.getters.getSelectInstance,
            status: id,
            page: '1'
        })
    }
}

export default {
    state, getters, mutations, actions
}
