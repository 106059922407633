export default {
"U+1F601.png":  "\xF0\x9F\x98\x81",
"U+1F602.png":  "\xF0\x9F\x98\x82",
"U+1F603.png":  "\xF0\x9F\x98\x83",
"U+1F604.png":  "\xF0\x9F\x98\x84",
"U+1F605.png":  "\xF0\x9F\x98\x85",
"U+1F606.png":  "\xF0\x9F\x98\x86",
"U+1F609.png":  "\xF0\x9F\x98\x89",
"U+1F60A.png":  "\xF0\x9F\x98\x8A",
"U+1F60B.png":  "\xF0\x9F\x98\x8B",
"U+1F60C.png":  "\xF0\x9F\x98\x8C",
"U+1F60D.png":  "\xF0\x9F\x98\x8D",
"U+1F60F.png":  "\xF0\x9F\x98\x8F",
"U+1F612.png":  "\xF0\x9F\x98\x92",
"U+1F613.png":  "\xF0\x9F\x98\x93",
"U+1F614.png":  "\xF0\x9F\x98\x94",
"U+1F616.png":  "\xF0\x9F\x98\x96",
"U+1F618.png":  "\xF0\x9F\x98\x98",
"U+1F61A.png":  "\xF0\x9F\x98\x9A",
"U+1F61C.png":  "\xF0\x9F\x98\x9C",
"U+1F61D.png":  "\xF0\x9F\x98\x9D",
"U+1F61E.png":  "\xF0\x9F\x98\x9E",
"U+1F620.png":  "\xF0\x9F\x98\xA0",
"U+1F621.png":  "\xF0\x9F\x98\xA1",
"U+1F622.png":  "\xF0\x9F\x98\xA2",
"U+1F623.png":  "\xF0\x9F\x98\xA3",
"U+1F624.png":  "\xF0\x9F\x98\xA4",
"U+1F625.png":  "\xF0\x9F\x98\xA5",
"U+1F628.png":  "\xF0\x9F\x98\xA8",
"U+1F629.png":  "\xF0\x9F\x98\xA9",
"U+1F62A.png":  "\xF0\x9F\x98\xAA",
"U+1F62B.png":  "\xF0\x9F\x98\xAB",
"U+1F62D.png":  "\xF0\x9F\x98\xAD",
"U+1F630.png":  "\xF0\x9F\x98\xB0",
"U+1F631.png":  "\xF0\x9F\x98\xB1",
"U+1F632.png":  "\xF0\x9F\x98\xB2",
"U+1F633.png":  "\xF0\x9F\x98\xB3",
"U+1F635.png":  "\xF0\x9F\x98\xB5",
"U+1F637.png":  "\xF0\x9F\x98\xB7",
"U+1F638.png":  "\xF0\x9F\x98\xB8",
"U+1F639.png":  "\xF0\x9F\x98\xB9",
"U+1F63A.png":  "\xF0\x9F\x98\xBA",
"U+1F63B.png":  "\xF0\x9F\x98\xBB",
"U+1F63C.png":  "\xF0\x9F\x98\xBC",
"U+1F63D.png":  "\xF0\x9F\x98\xBD",
"U+1F63E.png":  "\xF0\x9F\x98\xBE",
"U+1F63F.png":  "\xF0\x9F\x98\xBF",
"U+1F640.png":  "\xF0\x9F\x99\x80",
"U+1F645.png":  "\xF0\x9F\x99\x85",
"U+1F646.png":  "\xF0\x9F\x99\x86",
"U+1F647.png":  "\xF0\x9F\x99\x87",
"U+1F648.png":  "\xF0\x9F\x99\x88",
"U+1F649.png":  "\xF0\x9F\x99\x89",
"U+1F64A.png":  "\xF0\x9F\x99\x8A",
"U+1F64B.png":  "\xF0\x9F\x99\x8B",
"U+1F64C.png":  "\xF0\x9F\x99\x8C",
"U+1F64D.png":  "\xF0\x9F\x99\x8D",
"U+1F64E.png":  "\xF0\x9F\x99\x8E",
"U+1F64F.png":  "\xF0\x9F\x99\x8F",

"U+2702.png":  "\xE2\x9C\x82",
"U+2705.png":  "\xE2\x9C\x85",
"U+2708.png":  "\xE2\x9C\x88",
"U+2709.png":  "\xE2\x9C\x89",
"U+270A.png":  "\xE2\x9C\x8A",
"U+270B.png":  "\xE2\x9C\x8B",
"U+270C.png":  "\xE2\x9C\x8C",
"U+270F.png":  "\xE2\x9C\x8F",
"U+2712.png":  "\xE2\x9C\x92",
"U+2714.png":  "\xE2\x9C\x94",
"U+2716.png":  "\xE2\x9C\x96",
"U+2728.png":  "\xE2\x9C\xA8",
"U+2733.png":  "\xE2\x9C\xB3",
"U+2734.png":  "\xE2\x9C\xB4",
"U+2744.png":  "\xE2\x9D\x84",
"U+2747.png":  "\xE2\x9D\x87",
"U+274C.png":  "\xE2\x9D\x8C",
"U+274E.png":  "\xE2\x9D\x8E",
"U+2753.png":  "\xE2\x9D\x93",
"U+2754.png":  "\xE2\x9D\x94",
"U+2755.png":  "\xE2\x9D\x95",
"U+2757.png":  "\xE2\x9D\x97",
"U+2764.png":  "\xE2\x9D\xA4",
"U+2795.png":  "\xE2\x9E\x95",
"U+2796.png":  "\xE2\x9E\x96",
"U+2797.png":  "\xE2\x9E\x97",
"U+27A1.png":  "\xE2\x9E\xA1",
"U+27B0.png":  "\xE2\x9E\xB0",

"U+1F680.png":  "\xF0\x9F\x9A\x80",
"U+1F683.png":  "\xF0\x9F\x9A\x83",
"U+1F684.png":  "\xF0\x9F\x9A\x84",
"U+1F685.png":  "\xF0\x9F\x9A\x85",
"U+1F687.png":  "\xF0\x9F\x9A\x87",
"U+1F689.png":  "\xF0\x9F\x9A\x89",
"U+1F68C.png":  "\xF0\x9F\x9A\x8C",
"U+1F68F.png":  "\xF0\x9F\x9A\x8F",
"U+1F691.png":  "\xF0\x9F\x9A\x91",
"U+1F692.png":  "\xF0\x9F\x9A\x92",
"U+1F693.png":  "\xF0\x9F\x9A\x93",
"U+1F695.png":  "\xF0\x9F\x9A\x95",
"U+1F697.png":  "\xF0\x9F\x9A\x97",
"U+1F699.png":  "\xF0\x9F\x9A\x99",
"U+1F69A.png":  "\xF0\x9F\x9A\x9A",
"U+1F6A2.png":  "\xF0\x9F\x9A\xA2",
"U+1F6A4.png":  "\xF0\x9F\x9A\xA4",
"U+1F6A5.png":  "\xF0\x9F\x9A\xA5",
"U+1F6A7.png":  "\xF0\x9F\x9A\xA7",
"U+1F6A8.png":  "\xF0\x9F\x9A\xA8",
"U+1F6A9.png":  "\xF0\x9F\x9A\xA9",
"U+1F6AA.png":  "\xF0\x9F\x9A\xAA",
"U+1F6AB.png":  "\xF0\x9F\x9A\xAB",
"U+1F6AC.png":  "\xF0\x9F\x9A\xAC",
"U+1F6AD.png":  "\xF0\x9F\x9A\xAD",
"U+1F6B2.png":  "\xF0\x9F\x9A\xB2",
"U+1F6B6.png":  "\xF0\x9F\x9A\xB6",
"U+1F6B9.png":  "\xF0\x9F\x9A\xB9",
"U+1F6BA.png":  "\xF0\x9F\x9A\xBA",
"U+1F6BB.png":  "\xF0\x9F\x9A\xBB",
"U+1F6BC.png":  "\xF0\x9F\x9A\xBC",
"U+1F6BD.png":  "\xF0\x9F\x9A\xBD",
"U+1F6BE.png":  "\xF0\x9F\x9A\xBE",
"U+1F6C0.png":  "\xF0\x9F\x9B\x80",

"U+24C2.png":  "\xE2\x93\x82",
"U+1F170.png":  "\xF0\x9F\x85\xB0",
"U+1F171.png":  "\xF0\x9F\x85\xB1",
"U+1F17E.png":  "\xF0\x9F\x85\xBE",
"U+1F17F.png":  "\xF0\x9F\x85\xBF",
"U+1F18E.png":  "\xF0\x9F\x86\x8E",
"U+1F191.png":  "\xF0\x9F\x86\x91",
"U+1F192.png":  "\xF0\x9F\x86\x92",
"U+1F193.png":  "\xF0\x9F\x86\x93",
"U+1F194.png":  "\xF0\x9F\x86\x94",
"U+1F195.png":  "\xF0\x9F\x86\x95",
"U+1F196.png":  "\xF0\x9F\x86\x96",
"U+1F197.png":  "\xF0\x9F\x86\x97",
"U+1F198.png":  "\xF0\x9F\x86\x98",
"U+1F199.png":  "\xF0\x9F\x86\x99",
"U+1F19A.png":  "\xF0\x9F\x86\x9A",
"U+1F1E9 U+1F1EA.png":  "\xF0\x9F\x87\xA9\xF0\x9F\x87\xAA",
"U+1F1EC U+1F1E7.png":  "\xF0\x9F\x87\xAC\xF0\x9F\x87\xA7",
"U+1F1E8 U+1F1F3.png":  "\xF0\x9F\x87\xA8\xF0\x9F\x87\xB3",
"U+1F1EF U+1F1F5.png":  "\xF0\x9F\x87\xAF\xF0\x9F\x87\xB5",
"U+1F1EB U+1F1F7.png":  "\xF0\x9F\x87\xAB\xF0\x9F\x87\xB7",
"U+1F1F0 U+1F1F7.png":  "\xF0\x9F\x87\xB0\xF0\x9F\x87\xB7",
"U+1F1EA U+1F1F8.png":  "\xF0\x9F\x87\xAA\xF0\x9F\x87\xB8",
"U+1F1EE U+1F1F9.png":  "\xF0\x9F\x87\xAE\xF0\x9F\x87\xB9",
"U+1F1F7 U+1F1FA.png":  "\xF0\x9F\x87\xB7\xF0\x9F\x87\xBA",
"U+1F1FA U+1F1F8.png":  "\xF0\x9F\x87\xBA\xF0\x9F\x87\xB8",
"U+1F201.png":  "\xF0\x9F\x88\x81",
"U+1F202.png":  "\xF0\x9F\x88\x82",
"U+1F21A.png":  "\xF0\x9F\x88\x9A",
"U+1F22F.png":  "\xF0\x9F\x88\xAF",
"U+1F232.png":  "\xF0\x9F\x88\xB2",
"U+1F233.png":  "\xF0\x9F\x88\xB3",
"U+1F234.png":  "\xF0\x9F\x88\xB4",
"U+1F235.png":  "\xF0\x9F\x88\xB5",
"U+1F236.png":  "\xF0\x9F\x88\xB6",
"U+1F237.png":  "\xF0\x9F\x88\xB7",
"U+1F238.png":  "\xF0\x9F\x88\xB8",
"U+1F239.png":  "\xF0\x9F\x88\xB9",
"U+1F23A.png":  "\xF0\x9F\x88\xBA",
"U+1F250.png":  "\xF0\x9F\x89\x90",
"U+1F251.png":  "\xF0\x9F\x89\x91",

"U+00A9.png":  "\xC2\xA9",
"U+00AE.png":  "\xC2\xAE",
"U+203C.png":  "\xE2\x80\xBC",
"U+2049.png":  "\xE2\x81\x89",
"U+0023 U+20E3.png":  "\x23\xE2\x83\xA3",
"U+0038 U+20E3.png":  "\x38\xE2\x83\xA3",
"U+0039 U+20E3.png":  "\x39\xE2\x83\xA3",
"U+0037 U+20E3.png":  "\x37\xE2\x83\xA3",
"U+0030 U+20E3.png":  "\x30\xE2\x83\xA3",
"U+0036 U+20E3.png":  "\x36\xE2\x83\xA3",
"U+0035 U+20E3.png":  "\x35\xE2\x83\xA3",
"U+0034 U+20E3.png":  "\x34\xE2\x83\xA3",
"U+0033 U+20E3.png":  "\x33\xE2\x83\xA3",
"U+0032 U+20E3.png":  "\x32\xE2\x83\xA3",
"U+0031 U+20E3.png":  "\x31\xE2\x83\xA3",
"U+2122.png":  "\xE2\x84\xA2",
"U+2139.png":  "\xE2\x84\xB9",
"U+2194.png":  "\xE2\x86\x94",
"U+2195.png":  "\xE2\x86\x95",
"U+2196.png":  "\xE2\x86\x96",
"U+2197.png":  "\xE2\x86\x97",
"U+2198.png":  "\xE2\x86\x98",
"U+2199.png":  "\xE2\x86\x99",
"U+21A9.png":  "\xE2\x86\xA9",
"U+21AA.png":  "\xE2\x86\xAA",
"U+231A.png":  "\xE2\x8C\x9A",
"U+231B.png":  "\xE2\x8C\x9B",
"U+23E9.png":  "\xE2\x8F\xA9",
"U+23EA.png":  "\xE2\x8F\xAA",
"U+23EB.png":  "\xE2\x8F\xAB",
"U+23EC.png":  "\xE2\x8F\xAC",
"U+23F0.png":  "\xE2\x8F\xB0",
"U+23F3.png":  "\xE2\x8F\xB3",
"U+25AA.png":  "\xE2\x96\xAA",
"U+25AB.png":  "\xE2\x96\xAB",
"U+25B6.png":  "\xE2\x96\xB6",
"U+25C0.png":  "\xE2\x97\x80",
"U+25FB.png":  "\xE2\x97\xBB",
"U+25FC.png":  "\xE2\x97\xBC",
"U+25FD.png":  "\xE2\x97\xBD",
"U+25FE.png":  "\xE2\x97\xBE",
"U+2600.png":  "\xE2\x98\x80",
"U+2601.png":  "\xE2\x98\x81",
"U+260E.png":  "\xE2\x98\x8E",
"U+2611.png":  "\xE2\x98\x91",
"U+2614.png":  "\xE2\x98\x94",
"U+2615.png":  "\xE2\x98\x95",
"U+261D.png":  "\xE2\x98\x9D",
"U+263A.png":  "\xE2\x98\xBA",
"U+2648.png":  "\xE2\x99\x88",
"U+2649.png":  "\xE2\x99\x89",
"U+264A.png":  "\xE2\x99\x8A",
"U+264B.png":  "\xE2\x99\x8B",
"U+264C.png":  "\xE2\x99\x8C",
"U+264D.png":  "\xE2\x99\x8D",
"U+264E.png":  "\xE2\x99\x8E",
"U+264F.png":  "\xE2\x99\x8F",
"U+2650.png":  "\xE2\x99\x90",
"U+2651.png":  "\xE2\x99\x91",
"U+2652.png":  "\xE2\x99\x92",
"U+2653.png":  "\xE2\x99\x93",
"U+2660.png":  "\xE2\x99\xA0",
"U+2663.png":  "\xE2\x99\xA3",
"U+2665.png":  "\xE2\x99\xA5",
"U+2666.png":  "\xE2\x99\xA6",
"U+2668.png":  "\xE2\x99\xA8",
"U+267B.png":  "\xE2\x99\xBB",
"U+267F.png":  "\xE2\x99\xBF",
"U+2693.png":  "\xE2\x9A\x93",
"U+26A0.png":  "\xE2\x9A\xA0",
"U+26A1.png":  "\xE2\x9A\xA1",
"U+26AA.png":  "\xE2\x9A\xAA",
"U+26AB.png":  "\xE2\x9A\xAB",
"U+26BD.png":  "\xE2\x9A\xBD",
"U+26BE.png":  "\xE2\x9A\xBE",
"U+26C4.png":  "\xE2\x9B\x84",
"U+26C5.png":  "\xE2\x9B\x85",
"U+26CE.png":  "\xE2\x9B\x8E",
"U+26D4.png":  "\xE2\x9B\x94",
"U+26EA.png":  "\xE2\x9B\xAA",
"U+26F2.png":  "\xE2\x9B\xB2",
"U+26F3.png":  "\xE2\x9B\xB3",
"U+26F5.png":  "\xE2\x9B\xB5",
"U+26FA.png":  "\xE2\x9B\xBA",
"U+26FD.png":  "\xE2\x9B\xBD",
"U+2934.png":  "\xE2\xA4\xB4",
"U+2935.png":  "\xE2\xA4\xB5",
"U+2B05.png":  "\xE2\xAC\x85",
"U+2B06.png":  "\xE2\xAC\x86",
"U+2B07.png":  "\xE2\xAC\x87",
"U+2B1B.png":  "\xE2\xAC\x9B",
"U+2B1C.png":  "\xE2\xAC\x9C",
"U+2B50.png":  "\xE2\xAD\x90",
"U+2B55.png":  "\xE2\xAD\x95",
"U+3030.png":  "\xE3\x80\xB0",
"U+303D.png":  "\xE3\x80\xBD",
"U+3297.png":  "\xE3\x8A\x97",
"U+3299.png":  "\xE3\x8A\x99",
"U+1F004.png":  "\xF0\x9F\x80\x84",
"U+1F0CF.png":  "\xF0\x9F\x83\x8F",
"U+1F300.png":  "\xF0\x9F\x8C\x80",
"U+1F301.png":  "\xF0\x9F\x8C\x81",
"U+1F302.png":  "\xF0\x9F\x8C\x82",
"U+1F303.png":  "\xF0\x9F\x8C\x83",
"U+1F304.png":  "\xF0\x9F\x8C\x84",
"U+1F305.png":  "\xF0\x9F\x8C\x85",
"U+1F306.png":  "\xF0\x9F\x8C\x86",
"U+1F307.png":  "\xF0\x9F\x8C\x87",
"U+1F308.png":  "\xF0\x9F\x8C\x88",
"U+1F309.png":  "\xF0\x9F\x8C\x89",
"U+1F30A.png":  "\xF0\x9F\x8C\x8A",
"U+1F30B.png":  "\xF0\x9F\x8C\x8B",
"U+1F30C.png":  "\xF0\x9F\x8C\x8C",
"U+1F30F.png":  "\xF0\x9F\x8C\x8F",
"U+1F311.png":  "\xF0\x9F\x8C\x91",
"U+1F313.png":  "\xF0\x9F\x8C\x93",
"U+1F314.png":  "\xF0\x9F\x8C\x94",
"U+1F315.png":  "\xF0\x9F\x8C\x95",
"U+1F319.png":  "\xF0\x9F\x8C\x99",
"U+1F31B.png":  "\xF0\x9F\x8C\x9B",
"U+1F31F.png":  "\xF0\x9F\x8C\x9F",
"U+1F320.png":  "\xF0\x9F\x8C\xA0",
"U+1F330.png":  "\xF0\x9F\x8C\xB0",
"U+1F331.png":  "\xF0\x9F\x8C\xB1",
"U+1F334.png":  "\xF0\x9F\x8C\xB4",
"U+1F335.png":  "\xF0\x9F\x8C\xB5",
"U+1F337.png":  "\xF0\x9F\x8C\xB7",
"U+1F338.png":  "\xF0\x9F\x8C\xB8",
"U+1F339.png":  "\xF0\x9F\x8C\xB9",
"U+1F33A.png":  "\xF0\x9F\x8C\xBA",
"U+1F33B.png":  "\xF0\x9F\x8C\xBB",
"U+1F33C.png":  "\xF0\x9F\x8C\xBC",
"U+1F33D.png":  "\xF0\x9F\x8C\xBD",
"U+1F33E.png":  "\xF0\x9F\x8C\xBE",
"U+1F33F.png":  "\xF0\x9F\x8C\xBF",
"U+1F340.png":  "\xF0\x9F\x8D\x80",
"U+1F341.png":  "\xF0\x9F\x8D\x81",
"U+1F342.png":  "\xF0\x9F\x8D\x82",
"U+1F343.png":  "\xF0\x9F\x8D\x83",
"U+1F344.png":  "\xF0\x9F\x8D\x84",
"U+1F345.png":  "\xF0\x9F\x8D\x85",
"U+1F346.png":  "\xF0\x9F\x8D\x86",
"U+1F347.png":  "\xF0\x9F\x8D\x87",
"U+1F348.png":  "\xF0\x9F\x8D\x88",
"U+1F349.png":  "\xF0\x9F\x8D\x89",
"U+1F34A.png":  "\xF0\x9F\x8D\x8A",
"U+1F34C.png":  "\xF0\x9F\x8D\x8C",
"U+1F34D.png":  "\xF0\x9F\x8D\x8D",
"U+1F34E.png":  "\xF0\x9F\x8D\x8E",
"U+1F34F.png":  "\xF0\x9F\x8D\x8F",
"U+1F351.png":  "\xF0\x9F\x8D\x91",
"U+1F352.png":  "\xF0\x9F\x8D\x92",
"U+1F353.png":  "\xF0\x9F\x8D\x93",
"U+1F354.png":  "\xF0\x9F\x8D\x94",
"U+1F355.png":  "\xF0\x9F\x8D\x95",
"U+1F356.png":  "\xF0\x9F\x8D\x96",
"U+1F357.png":  "\xF0\x9F\x8D\x97",
"U+1F358.png":  "\xF0\x9F\x8D\x98",
"U+1F359.png":  "\xF0\x9F\x8D\x99",
"U+1F35A.png":  "\xF0\x9F\x8D\x9A",
"U+1F35B.png":  "\xF0\x9F\x8D\x9B",
"U+1F35C.png":  "\xF0\x9F\x8D\x9C",
"U+1F35D.png":  "\xF0\x9F\x8D\x9D",
"U+1F35E.png":  "\xF0\x9F\x8D\x9E",
"U+1F35F.png":  "\xF0\x9F\x8D\x9F",
"U+1F360.png":  "\xF0\x9F\x8D\xA0",
"U+1F361.png":  "\xF0\x9F\x8D\xA1",
"U+1F362.png":  "\xF0\x9F\x8D\xA2",
"U+1F363.png":  "\xF0\x9F\x8D\xA3",
"U+1F364.png":  "\xF0\x9F\x8D\xA4",
"U+1F365.png":  "\xF0\x9F\x8D\xA5",
"U+1F366.png":  "\xF0\x9F\x8D\xA6",
"U+1F367.png":  "\xF0\x9F\x8D\xA7",
"U+1F368.png":  "\xF0\x9F\x8D\xA8",
"U+1F369.png":  "\xF0\x9F\x8D\xA9",
"U+1F36A.png":  "\xF0\x9F\x8D\xAA",
"U+1F36B.png":  "\xF0\x9F\x8D\xAB",
"U+1F36C.png":  "\xF0\x9F\x8D\xAC",
"U+1F36D.png":  "\xF0\x9F\x8D\xAD",
"U+1F36E.png":  "\xF0\x9F\x8D\xAE",
"U+1F36F.png":  "\xF0\x9F\x8D\xAF",
"U+1F370.png":  "\xF0\x9F\x8D\xB0",
"U+1F371.png":  "\xF0\x9F\x8D\xB1",
"U+1F372.png":  "\xF0\x9F\x8D\xB2",
"U+1F373.png":  "\xF0\x9F\x8D\xB3",
"U+1F374.png":  "\xF0\x9F\x8D\xB4",
"U+1F375.png":  "\xF0\x9F\x8D\xB5",
"U+1F376.png":  "\xF0\x9F\x8D\xB6",
"U+1F377.png":  "\xF0\x9F\x8D\xB7",
"U+1F378.png":  "\xF0\x9F\x8D\xB8",
"U+1F379.png":  "\xF0\x9F\x8D\xB9",
"U+1F37A.png":  "\xF0\x9F\x8D\xBA",
"U+1F37B.png":  "\xF0\x9F\x8D\xBB",
"U+1F380.png":  "\xF0\x9F\x8E\x80",
"U+1F381.png":  "\xF0\x9F\x8E\x81",
"U+1F382.png":  "\xF0\x9F\x8E\x82",
"U+1F383.png":  "\xF0\x9F\x8E\x83",
"U+1F384.png":  "\xF0\x9F\x8E\x84",
"U+1F385.png":  "\xF0\x9F\x8E\x85",
"U+1F386.png":  "\xF0\x9F\x8E\x86",
"U+1F387.png":  "\xF0\x9F\x8E\x87",
"U+1F388.png":  "\xF0\x9F\x8E\x88",
"U+1F389.png":  "\xF0\x9F\x8E\x89",
"U+1F38A.png":  "\xF0\x9F\x8E\x8A",
"U+1F38B.png":  "\xF0\x9F\x8E\x8B",
"U+1F38C.png":  "\xF0\x9F\x8E\x8C",
"U+1F38D.png":  "\xF0\x9F\x8E\x8D",
"U+1F38E.png":  "\xF0\x9F\x8E\x8E",
"U+1F38F.png":  "\xF0\x9F\x8E\x8F",
"U+1F390.png":  "\xF0\x9F\x8E\x90",
"U+1F391.png":  "\xF0\x9F\x8E\x91",
"U+1F392.png":  "\xF0\x9F\x8E\x92",
"U+1F393.png":  "\xF0\x9F\x8E\x93",
"U+1F3A0.png":  "\xF0\x9F\x8E\xA0",
"U+1F3A1.png":  "\xF0\x9F\x8E\xA1",
"U+1F3A2.png":  "\xF0\x9F\x8E\xA2",
"U+1F3A3.png":  "\xF0\x9F\x8E\xA3",
"U+1F3A4.png":  "\xF0\x9F\x8E\xA4",
"U+1F3A5.png":  "\xF0\x9F\x8E\xA5",
"U+1F3A6.png":  "\xF0\x9F\x8E\xA6",
"U+1F3A7.png":  "\xF0\x9F\x8E\xA7",
"U+1F3A8.png":  "\xF0\x9F\x8E\xA8",
"U+1F3A9.png":  "\xF0\x9F\x8E\xA9",
"U+1F3AA.png":  "\xF0\x9F\x8E\xAA",
"U+1F3AB.png":  "\xF0\x9F\x8E\xAB",
"U+1F3AC.png":  "\xF0\x9F\x8E\xAC",
"U+1F3AD.png":  "\xF0\x9F\x8E\xAD",
"U+1F3AE.png":  "\xF0\x9F\x8E\xAE",
"U+1F3AF.png":  "\xF0\x9F\x8E\xAF",
"U+1F3B0.png":  "\xF0\x9F\x8E\xB0",
"U+1F3B1.png":  "\xF0\x9F\x8E\xB1",
"U+1F3B2.png":  "\xF0\x9F\x8E\xB2",
"U+1F3B3.png":  "\xF0\x9F\x8E\xB3",
"U+1F3B4.png":  "\xF0\x9F\x8E\xB4",
"U+1F3B5.png":  "\xF0\x9F\x8E\xB5",
"U+1F3B6.png":  "\xF0\x9F\x8E\xB6",
"U+1F3B7.png":  "\xF0\x9F\x8E\xB7",
"U+1F3B8.png":  "\xF0\x9F\x8E\xB8",
"U+1F3B9.png":  "\xF0\x9F\x8E\xB9",
"U+1F3BA.png":  "\xF0\x9F\x8E\xBA",
"U+1F3BB.png":  "\xF0\x9F\x8E\xBB",
"U+1F3BC.png":  "\xF0\x9F\x8E\xBC",
"U+1F3BD.png":  "\xF0\x9F\x8E\xBD",
"U+1F3BE.png":  "\xF0\x9F\x8E\xBE",
"U+1F3BF.png":  "\xF0\x9F\x8E\xBF",
"U+1F3C0.png":  "\xF0\x9F\x8F\x80",
"U+1F3C1.png":  "\xF0\x9F\x8F\x81",
"U+1F3C2.png":  "\xF0\x9F\x8F\x82",
"U+1F3C3.png":  "\xF0\x9F\x8F\x83",
"U+1F3C4.png":  "\xF0\x9F\x8F\x84",
"U+1F3C6.png":  "\xF0\x9F\x8F\x86",
"U+1F3C8.png":  "\xF0\x9F\x8F\x88",
"U+1F3CA.png":  "\xF0\x9F\x8F\x8A",
"U+1F3E0.png":  "\xF0\x9F\x8F\xA0",
"U+1F3E1.png":  "\xF0\x9F\x8F\xA1",
"U+1F3E2.png":  "\xF0\x9F\x8F\xA2",
"U+1F3E3.png":  "\xF0\x9F\x8F\xA3",
"U+1F3E5.png":  "\xF0\x9F\x8F\xA5",
"U+1F3E6.png":  "\xF0\x9F\x8F\xA6",
"U+1F3E7.png":  "\xF0\x9F\x8F\xA7",
"U+1F3E8.png":  "\xF0\x9F\x8F\xA8",
"U+1F3E9.png":  "\xF0\x9F\x8F\xA9",
"U+1F3EA.png":  "\xF0\x9F\x8F\xAA",
"U+1F3EB.png":  "\xF0\x9F\x8F\xAB",
"U+1F3EC.png":  "\xF0\x9F\x8F\xAC",
"U+1F3ED.png":  "\xF0\x9F\x8F\xAD",
"U+1F3EE.png":  "\xF0\x9F\x8F\xAE",
"U+1F3EF.png":  "\xF0\x9F\x8F\xAF",
"U+1F3F0.png":  "\xF0\x9F\x8F\xB0",
"U+1F40C.png":  "\xF0\x9F\x90\x8C",
"U+1F40D.png":  "\xF0\x9F\x90\x8D",
"U+1F40E.png":  "\xF0\x9F\x90\x8E",
"U+1F411.png":  "\xF0\x9F\x90\x91",
"U+1F412.png":  "\xF0\x9F\x90\x92",
"U+1F414.png":  "\xF0\x9F\x90\x94",
"U+1F417.png":  "\xF0\x9F\x90\x97",
"U+1F418.png":  "\xF0\x9F\x90\x98",
"U+1F419.png":  "\xF0\x9F\x90\x99",
"U+1F41A.png":  "\xF0\x9F\x90\x9A",
"U+1F41B.png":  "\xF0\x9F\x90\x9B",
"U+1F41C.png":  "\xF0\x9F\x90\x9C",
"U+1F41D.png":  "\xF0\x9F\x90\x9D",
"U+1F41E.png":  "\xF0\x9F\x90\x9E",
"U+1F41F.png":  "\xF0\x9F\x90\x9F",
"U+1F420.png":  "\xF0\x9F\x90\xA0",
"U+1F421.png":  "\xF0\x9F\x90\xA1",
"U+1F422.png":  "\xF0\x9F\x90\xA2",
"U+1F423.png":  "\xF0\x9F\x90\xA3",
"U+1F424.png":  "\xF0\x9F\x90\xA4",
"U+1F425.png":  "\xF0\x9F\x90\xA5",
"U+1F426.png":  "\xF0\x9F\x90\xA6",
"U+1F427.png":  "\xF0\x9F\x90\xA7",
"U+1F428.png":  "\xF0\x9F\x90\xA8",
"U+1F429.png":  "\xF0\x9F\x90\xA9",
"U+1F42B.png":  "\xF0\x9F\x90\xAB",
"U+1F42C.png":  "\xF0\x9F\x90\xAC",
"U+1F42D.png":  "\xF0\x9F\x90\xAD",
"U+1F42E.png":  "\xF0\x9F\x90\xAE",
"U+1F42F.png":  "\xF0\x9F\x90\xAF",
"U+1F430.png":  "\xF0\x9F\x90\xB0",
"U+1F431.png":  "\xF0\x9F\x90\xB1",
"U+1F432.png":  "\xF0\x9F\x90\xB2",
"U+1F433.png":  "\xF0\x9F\x90\xB3",
"U+1F434.png":  "\xF0\x9F\x90\xB4",
"U+1F435.png":  "\xF0\x9F\x90\xB5",
"U+1F436.png":  "\xF0\x9F\x90\xB6",
"U+1F437.png":  "\xF0\x9F\x90\xB7",
"U+1F438.png":  "\xF0\x9F\x90\xB8",
"U+1F439.png":  "\xF0\x9F\x90\xB9",
"U+1F43A.png":  "\xF0\x9F\x90\xBA",
"U+1F43B.png":  "\xF0\x9F\x90\xBB",
"U+1F43C.png":  "\xF0\x9F\x90\xBC",
"U+1F43D.png":  "\xF0\x9F\x90\xBD",
"U+1F43E.png":  "\xF0\x9F\x90\xBE",
"U+1F440.png":  "\xF0\x9F\x91\x80",
"U+1F442.png":  "\xF0\x9F\x91\x82",
"U+1F443.png":  "\xF0\x9F\x91\x83",
"U+1F444.png":  "\xF0\x9F\x91\x84",
"U+1F445.png":  "\xF0\x9F\x91\x85",
"U+1F446.png":  "\xF0\x9F\x91\x86",
"U+1F447.png":  "\xF0\x9F\x91\x87",
"U+1F448.png":  "\xF0\x9F\x91\x88",
"U+1F449.png":  "\xF0\x9F\x91\x89",
"U+1F44A.png":  "\xF0\x9F\x91\x8A",
"U+1F44B.png":  "\xF0\x9F\x91\x8B",
"U+1F44C.png":  "\xF0\x9F\x91\x8C",
"U+1F44D.png":  "\xF0\x9F\x91\x8D",
"U+1F44E.png":  "\xF0\x9F\x91\x8E",
"U+1F44F.png":  "\xF0\x9F\x91\x8F",
"U+1F450.png":  "\xF0\x9F\x91\x90",
"U+1F451.png":  "\xF0\x9F\x91\x91",
"U+1F452.png":  "\xF0\x9F\x91\x92",
"U+1F453.png":  "\xF0\x9F\x91\x93",
"U+1F454.png":  "\xF0\x9F\x91\x94",
"U+1F455.png":  "\xF0\x9F\x91\x95",
"U+1F456.png":  "\xF0\x9F\x91\x96",
"U+1F457.png":  "\xF0\x9F\x91\x97",
"U+1F458.png":  "\xF0\x9F\x91\x98",
"U+1F459.png":  "\xF0\x9F\x91\x99",
"U+1F45A.png":  "\xF0\x9F\x91\x9A",
"U+1F45B.png":  "\xF0\x9F\x91\x9B",
"U+1F45C.png":  "\xF0\x9F\x91\x9C",
"U+1F45D.png":  "\xF0\x9F\x91\x9D",
"U+1F45E.png":  "\xF0\x9F\x91\x9E",
"U+1F45F.png":  "\xF0\x9F\x91\x9F",
"U+1F460.png":  "\xF0\x9F\x91\xA0",
"U+1F461.png":  "\xF0\x9F\x91\xA1",
"U+1F462.png":  "\xF0\x9F\x91\xA2",
"U+1F463.png":  "\xF0\x9F\x91\xA3",
"U+1F464.png":  "\xF0\x9F\x91\xA4",
"U+1F466.png":  "\xF0\x9F\x91\xA6",
"U+1F467.png":  "\xF0\x9F\x91\xA7",
"U+1F468.png":  "\xF0\x9F\x91\xA8",
"U+1F469.png":  "\xF0\x9F\x91\xA9",
"U+1F46A.png":  "\xF0\x9F\x91\xAA",
"U+1F46B.png":  "\xF0\x9F\x91\xAB",
"U+1F46E.png":  "\xF0\x9F\x91\xAE",
"U+1F46F.png":  "\xF0\x9F\x91\xAF",
"U+1F470.png":  "\xF0\x9F\x91\xB0",
"U+1F471.png":  "\xF0\x9F\x91\xB1",
"U+1F472.png":  "\xF0\x9F\x91\xB2",
"U+1F473.png":  "\xF0\x9F\x91\xB3",
"U+1F474.png":  "\xF0\x9F\x91\xB4",
"U+1F475.png":  "\xF0\x9F\x91\xB5",
"U+1F476.png":  "\xF0\x9F\x91\xB6",
"U+1F477.png":  "\xF0\x9F\x91\xB7",
"U+1F478.png":  "\xF0\x9F\x91\xB8",
"U+1F479.png":  "\xF0\x9F\x91\xB9",
"U+1F47A.png":  "\xF0\x9F\x91\xBA",
"U+1F47B.png":  "\xF0\x9F\x91\xBB",
"U+1F47C.png":  "\xF0\x9F\x91\xBC",
"U+1F47D.png":  "\xF0\x9F\x91\xBD",
"U+1F47E.png":  "\xF0\x9F\x91\xBE",
"U+1F47F.png":  "\xF0\x9F\x91\xBF",
"U+1F480.png":  "\xF0\x9F\x92\x80",
"U+1F481.png":  "\xF0\x9F\x92\x81",
"U+1F482.png":  "\xF0\x9F\x92\x82",
"U+1F483.png":  "\xF0\x9F\x92\x83",
"U+1F484.png":  "\xF0\x9F\x92\x84",
"U+1F485.png":  "\xF0\x9F\x92\x85",
"U+1F486.png":  "\xF0\x9F\x92\x86",
"U+1F487.png":  "\xF0\x9F\x92\x87",
"U+1F488.png":  "\xF0\x9F\x92\x88",
"U+1F489.png":  "\xF0\x9F\x92\x89",
"U+1F48A.png":  "\xF0\x9F\x92\x8A",
"U+1F48B.png":  "\xF0\x9F\x92\x8B",
"U+1F48C.png":  "\xF0\x9F\x92\x8C",
"U+1F48D.png":  "\xF0\x9F\x92\x8D",
"U+1F48E.png":  "\xF0\x9F\x92\x8E",
"U+1F48F.png":  "\xF0\x9F\x92\x8F",
"U+1F490.png":  "\xF0\x9F\x92\x90",
"U+1F491.png":  "\xF0\x9F\x92\x91",
"U+1F492.png":  "\xF0\x9F\x92\x92",
"U+1F493.png":  "\xF0\x9F\x92\x93",
"U+1F494.png":  "\xF0\x9F\x92\x94",
"U+1F495.png":  "\xF0\x9F\x92\x95",
"U+1F496.png":  "\xF0\x9F\x92\x96",
"U+1F497.png":  "\xF0\x9F\x92\x97",
"U+1F498.png":  "\xF0\x9F\x92\x98",
"U+1F499.png":  "\xF0\x9F\x92\x99",
"U+1F49A.png":  "\xF0\x9F\x92\x9A",
"U+1F49B.png":  "\xF0\x9F\x92\x9B",
"U+1F49C.png":  "\xF0\x9F\x92\x9C",
"U+1F49D.png":  "\xF0\x9F\x92\x9D",
"U+1F49E.png":  "\xF0\x9F\x92\x9E",
"U+1F49F.png":  "\xF0\x9F\x92\x9F",
"U+1F4A0.png":  "\xF0\x9F\x92\xA0",
"U+1F4A1.png":  "\xF0\x9F\x92\xA1",
"U+1F4A2.png":  "\xF0\x9F\x92\xA2",
"U+1F4A3.png":  "\xF0\x9F\x92\xA3",
"U+1F4A4.png":  "\xF0\x9F\x92\xA4",
"U+1F4A5.png":  "\xF0\x9F\x92\xA5",
"U+1F4A6.png":  "\xF0\x9F\x92\xA6",
"U+1F4A7.png":  "\xF0\x9F\x92\xA7",
"U+1F4A8.png":  "\xF0\x9F\x92\xA8",
"U+1F4A9.png":  "\xF0\x9F\x92\xA9",
"U+1F4AA.png":  "\xF0\x9F\x92\xAA",
"U+1F4AB.png":  "\xF0\x9F\x92\xAB",
"U+1F4AC.png":  "\xF0\x9F\x92\xAC",
"U+1F4AE.png":  "\xF0\x9F\x92\xAE",
"U+1F4AF.png":  "\xF0\x9F\x92\xAF",
"U+1F4B0.png":  "\xF0\x9F\x92\xB0",
"U+1F4B1.png":  "\xF0\x9F\x92\xB1",
"U+1F4B2.png":  "\xF0\x9F\x92\xB2",
"U+1F4B3.png":  "\xF0\x9F\x92\xB3",
"U+1F4B4.png":  "\xF0\x9F\x92\xB4",
"U+1F4B5.png":  "\xF0\x9F\x92\xB5",
"U+1F4B8.png":  "\xF0\x9F\x92\xB8",
"U+1F4B9.png":  "\xF0\x9F\x92\xB9",
"U+1F4BA.png":  "\xF0\x9F\x92\xBA",
"U+1F4BB.png":  "\xF0\x9F\x92\xBB",
"U+1F4BC.png":  "\xF0\x9F\x92\xBC",
"U+1F4BD.png":  "\xF0\x9F\x92\xBD",
"U+1F4BE.png":  "\xF0\x9F\x92\xBE",
"U+1F4BF.png":  "\xF0\x9F\x92\xBF",
"U+1F4C0.png":  "\xF0\x9F\x93\x80",
"U+1F4C1.png":  "\xF0\x9F\x93\x81",
"U+1F4C2.png":  "\xF0\x9F\x93\x82",
"U+1F4C3.png":  "\xF0\x9F\x93\x83",
"U+1F4C4.png":  "\xF0\x9F\x93\x84",
"U+1F4C5.png":  "\xF0\x9F\x93\x85",
"U+1F4C6.png":  "\xF0\x9F\x93\x86",
"U+1F4C7.png":  "\xF0\x9F\x93\x87",
"U+1F4C8.png":  "\xF0\x9F\x93\x88",
"U+1F4C9.png":  "\xF0\x9F\x93\x89",
"U+1F4CA.png":  "\xF0\x9F\x93\x8A",
"U+1F4CB.png":  "\xF0\x9F\x93\x8B",
"U+1F4CC.png":  "\xF0\x9F\x93\x8C",
"U+1F4CD.png":  "\xF0\x9F\x93\x8D",
"U+1F4CE.png":  "\xF0\x9F\x93\x8E",
"U+1F4CF.png":  "\xF0\x9F\x93\x8F",
"U+1F4D0.png":  "\xF0\x9F\x93\x90",
"U+1F4D1.png":  "\xF0\x9F\x93\x91",
"U+1F4D2.png":  "\xF0\x9F\x93\x92",
"U+1F4D3.png":  "\xF0\x9F\x93\x93",
"U+1F4D4.png":  "\xF0\x9F\x93\x94",
"U+1F4D5.png":  "\xF0\x9F\x93\x95",
"U+1F4D6.png":  "\xF0\x9F\x93\x96",
"U+1F4D7.png":  "\xF0\x9F\x93\x97",
"U+1F4D8.png":  "\xF0\x9F\x93\x98",
"U+1F4D9.png":  "\xF0\x9F\x93\x99",
"U+1F4DA.png":  "\xF0\x9F\x93\x9A",
"U+1F4DB.png":  "\xF0\x9F\x93\x9B",
"U+1F4DC.png":  "\xF0\x9F\x93\x9C",
"U+1F4DD.png":  "\xF0\x9F\x93\x9D",
"U+1F4DE.png":  "\xF0\x9F\x93\x9E",
"U+1F4DF.png":  "\xF0\x9F\x93\x9F",
"U+1F4E0.png":  "\xF0\x9F\x93\xA0",
"U+1F4E1.png":  "\xF0\x9F\x93\xA1",
"U+1F4E2.png":  "\xF0\x9F\x93\xA2",
"U+1F4E3.png":  "\xF0\x9F\x93\xA3",
"U+1F4E4.png":  "\xF0\x9F\x93\xA4",
"U+1F4E5.png":  "\xF0\x9F\x93\xA5",
"U+1F4E6.png":  "\xF0\x9F\x93\xA6",
"U+1F4E7.png":  "\xF0\x9F\x93\xA7",
"U+1F4E8.png":  "\xF0\x9F\x93\xA8",
"U+1F4E9.png":  "\xF0\x9F\x93\xA9",
"U+1F4EA.png":  "\xF0\x9F\x93\xAA",
"U+1F4EB.png":  "\xF0\x9F\x93\xAB",
"U+1F4EE.png":  "\xF0\x9F\x93\xAE",
"U+1F4F0.png":  "\xF0\x9F\x93\xB0",
"U+1F4F1.png":  "\xF0\x9F\x93\xB1",
"U+1F4F2.png":  "\xF0\x9F\x93\xB2",
"U+1F4F3.png":  "\xF0\x9F\x93\xB3",
"U+1F4F4.png":  "\xF0\x9F\x93\xB4",
"U+1F4F6.png":  "\xF0\x9F\x93\xB6",
"U+1F4F7.png":  "\xF0\x9F\x93\xB7",
"U+1F4F9.png":  "\xF0\x9F\x93\xB9",
"U+1F4FA.png":  "\xF0\x9F\x93\xBA",
"U+1F4FB.png":  "\xF0\x9F\x93\xBB",
"U+1F4FC.png":  "\xF0\x9F\x93\xBC",
"U+1F503.png":  "\xF0\x9F\x94\x83",
"U+1F50A.png":  "\xF0\x9F\x94\x8A",
"U+1F50B.png":  "\xF0\x9F\x94\x8B",
"U+1F50C.png":  "\xF0\x9F\x94\x8C",
"U+1F50D.png":  "\xF0\x9F\x94\x8D",
"U+1F50E.png":  "\xF0\x9F\x94\x8E",
"U+1F50F.png":  "\xF0\x9F\x94\x8F",
"U+1F510.png":  "\xF0\x9F\x94\x90",
"U+1F511.png":  "\xF0\x9F\x94\x91",
"U+1F512.png":  "\xF0\x9F\x94\x92",
"U+1F513.png":  "\xF0\x9F\x94\x93",
"U+1F514.png":  "\xF0\x9F\x94\x94",
"U+1F516.png":  "\xF0\x9F\x94\x96",
"U+1F517.png":  "\xF0\x9F\x94\x97",
"U+1F518.png":  "\xF0\x9F\x94\x98",
"U+1F519.png":  "\xF0\x9F\x94\x99",
"U+1F51A.png":  "\xF0\x9F\x94\x9A",
"U+1F51B.png":  "\xF0\x9F\x94\x9B",
"U+1F51C.png":  "\xF0\x9F\x94\x9C",
"U+1F51D.png":  "\xF0\x9F\x94\x9D",
"U+1F51E.png":  "\xF0\x9F\x94\x9E",
"U+1F51F.png":  "\xF0\x9F\x94\x9F",
"U+1F520.png":  "\xF0\x9F\x94\xA0",
"U+1F521.png":  "\xF0\x9F\x94\xA1",
"U+1F522.png":  "\xF0\x9F\x94\xA2",
"U+1F523.png":  "\xF0\x9F\x94\xA3",
"U+1F524.png":  "\xF0\x9F\x94\xA4",
"U+1F525.png":  "\xF0\x9F\x94\xA5",
"U+1F526.png":  "\xF0\x9F\x94\xA6",
"U+1F527.png":  "\xF0\x9F\x94\xA7",
"U+1F528.png":  "\xF0\x9F\x94\xA8",
"U+1F529.png":  "\xF0\x9F\x94\xA9",
"U+1F52A.png":  "\xF0\x9F\x94\xAA",
"U+1F52B.png":  "\xF0\x9F\x94\xAB",
"U+1F52E.png":  "\xF0\x9F\x94\xAE",
"U+1F52F.png":  "\xF0\x9F\x94\xAF",
"U+1F530.png":  "\xF0\x9F\x94\xB0",
"U+1F531.png":  "\xF0\x9F\x94\xB1",
"U+1F532.png":  "\xF0\x9F\x94\xB2",
"U+1F533.png":  "\xF0\x9F\x94\xB3",
"U+1F534.png":  "\xF0\x9F\x94\xB4",
"U+1F535.png":  "\xF0\x9F\x94\xB5",
"U+1F536.png":  "\xF0\x9F\x94\xB6",
"U+1F537.png":  "\xF0\x9F\x94\xB7",
"U+1F538.png":  "\xF0\x9F\x94\xB8",
"U+1F539.png":  "\xF0\x9F\x94\xB9",
"U+1F53A.png":  "\xF0\x9F\x94\xBA",
"U+1F53B.png":  "\xF0\x9F\x94\xBB",
"U+1F53C.png":  "\xF0\x9F\x94\xBC",
"U+1F53D.png":  "\xF0\x9F\x94\xBD",
"U+1F550.png":  "\xF0\x9F\x95\x90",
"U+1F551.png":  "\xF0\x9F\x95\x91",
"U+1F552.png":  "\xF0\x9F\x95\x92",
"U+1F553.png":  "\xF0\x9F\x95\x93",
"U+1F554.png":  "\xF0\x9F\x95\x94",
"U+1F555.png":  "\xF0\x9F\x95\x95",
"U+1F556.png":  "\xF0\x9F\x95\x96",
"U+1F557.png":  "\xF0\x9F\x95\x97",
"U+1F558.png":  "\xF0\x9F\x95\x98",
"U+1F559.png":  "\xF0\x9F\x95\x99",
"U+1F55A.png":  "\xF0\x9F\x95\x9A",
"U+1F55B.png":  "\xF0\x9F\x95\x9B",
"U+1F5FB.png":  "\xF0\x9F\x97\xBB",
"U+1F5FC.png":  "\xF0\x9F\x97\xBC",
"U+1F5FD.png":  "\xF0\x9F\x97\xBD",
"U+1F5FE.png":  "\xF0\x9F\x97\xBE",
"U+1F5FF.png":  "\xF0\x9F\x97\xBF",

"U+1F600.png":  "\xF0\x9F\x98\x80",
"U+1F607.png":  "\xF0\x9F\x98\x87",
"U+1F608.png":  "\xF0\x9F\x98\x88",
"U+1F60E.png":  "\xF0\x9F\x98\x8E",
"U+1F610.png":  "\xF0\x9F\x98\x90",
"U+1F611.png":  "\xF0\x9F\x98\x91",
"U+1F615.png":  "\xF0\x9F\x98\x95",
"U+1F617.png":  "\xF0\x9F\x98\x97",
"U+1F619.png":  "\xF0\x9F\x98\x99",
"U+1F61B.png":  "\xF0\x9F\x98\x9B",
"U+1F61F.png":  "\xF0\x9F\x98\x9F",
"U+1F626.png":  "\xF0\x9F\x98\xA6",
"U+1F627.png":  "\xF0\x9F\x98\xA7",
"U+1F62C.png":  "\xF0\x9F\x98\xAC",
"U+1F62E.png":  "\xF0\x9F\x98\xAE",
"U+1F62F.png":  "\xF0\x9F\x98\xAF",
"U+1F634.png":  "\xF0\x9F\x98\xB4",
"U+1F636.png":  "\xF0\x9F\x98\xB6",

"U+1F681.png":  "\xF0\x9F\x9A\x81",
"U+1F682.png":  "\xF0\x9F\x9A\x82",
"U+1F686.png":  "\xF0\x9F\x9A\x86",
"U+1F688.png":  "\xF0\x9F\x9A\x88",
"U+1F68A.png":  "\xF0\x9F\x9A\x8A",
"U+1F68D.png":  "\xF0\x9F\x9A\x8D",
"U+1F68E.png":  "\xF0\x9F\x9A\x8E",
"U+1F690.png":  "\xF0\x9F\x9A\x90",
"U+1F694.png":  "\xF0\x9F\x9A\x94",
"U+1F696.png":  "\xF0\x9F\x9A\x96",
"U+1F698.png":  "\xF0\x9F\x9A\x98",
"U+1F69B.png":  "\xF0\x9F\x9A\x9B",
"U+1F69C.png":  "\xF0\x9F\x9A\x9C",
"U+1F69D.png":  "\xF0\x9F\x9A\x9D",
"U+1F69E.png":  "\xF0\x9F\x9A\x9E",
"U+1F69F.png":  "\xF0\x9F\x9A\x9F",
"U+1F6A0.png":  "\xF0\x9F\x9A\xA0",
"U+1F6A1.png":  "\xF0\x9F\x9A\xA1",
"U+1F6A3.png":  "\xF0\x9F\x9A\xA3",
"U+1F6A6.png":  "\xF0\x9F\x9A\xA6",
"U+1F6AE.png":  "\xF0\x9F\x9A\xAE",
"U+1F6AF.png":  "\xF0\x9F\x9A\xAF",
"U+1F6B0.png":  "\xF0\x9F\x9A\xB0",
"U+1F6B1.png":  "\xF0\x9F\x9A\xB1",
"U+1F6B3.png":  "\xF0\x9F\x9A\xB3",
"U+1F6B4.png":  "\xF0\x9F\x9A\xB4",
"U+1F6B5.png":  "\xF0\x9F\x9A\xB5",
"U+1F6B7.png":  "\xF0\x9F\x9A\xB7",
"U+1F6B8.png":  "\xF0\x9F\x9A\xB8",
"U+1F6BF.png":  "\xF0\x9F\x9A\xBF",
"U+1F6C1.png":  "\xF0\x9F\x9B\x81",
"U+1F6C2.png":  "\xF0\x9F\x9B\x82",
"U+1F6C3.png":  "\xF0\x9F\x9B\x83",
"U+1F6C4.png":  "\xF0\x9F\x9B\x84",
"U+1F6C5.png":  "\xF0\x9F\x9B\x85",

"U+1F30D.png":  "\xF0\x9F\x8C\x8D",
"U+1F30E.png":  "\xF0\x9F\x8C\x8E",
"U+1F310.png":  "\xF0\x9F\x8C\x90",
"U+1F312.png":  "\xF0\x9F\x8C\x92",
"U+1F316.png":  "\xF0\x9F\x8C\x96",
"U+1F317.png":  "\xF0\x9F\x8C\x97",
"U+1F318.png":  "\xF0\x9F\x8C\x98",
"U+1F31A.png":  "\xF0\x9F\x8C\x9A",
"U+1F31C.png":  "\xF0\x9F\x8C\x9C",
"U+1F31D.png":  "\xF0\x9F\x8C\x9D",
"U+1F31E.png":  "\xF0\x9F\x8C\x9E",
"U+1F332.png":  "\xF0\x9F\x8C\xB2",
"U+1F333.png":  "\xF0\x9F\x8C\xB3",
"U+1F34B.png":  "\xF0\x9F\x8D\x8B",
"U+1F350.png":  "\xF0\x9F\x8D\x90",
"U+1F37C.png":  "\xF0\x9F\x8D\xBC",
"U+1F3C7.png":  "\xF0\x9F\x8F\x87",
"U+1F3C9.png":  "\xF0\x9F\x8F\x89",
"U+1F3E4.png":  "\xF0\x9F\x8F\xA4",
"U+1F400.png":  "\xF0\x9F\x90\x80",
"U+1F401.png":  "\xF0\x9F\x90\x81",
"U+1F402.png":  "\xF0\x9F\x90\x82",
"U+1F403.png":  "\xF0\x9F\x90\x83",
"U+1F404.png":  "\xF0\x9F\x90\x84",
"U+1F405.png":  "\xF0\x9F\x90\x85",
"U+1F406.png":  "\xF0\x9F\x90\x86",
"U+1F407.png":  "\xF0\x9F\x90\x87",
"U+1F408.png":  "\xF0\x9F\x90\x88",
"U+1F409.png":  "\xF0\x9F\x90\x89",
"U+1F40A.png":  "\xF0\x9F\x90\x8A",
"U+1F40B.png":  "\xF0\x9F\x90\x8B",
"U+1F40F.png":  "\xF0\x9F\x90\x8F",
"U+1F410.png":  "\xF0\x9F\x90\x90",
"U+1F413.png":  "\xF0\x9F\x90\x93",
"U+1F415.png":  "\xF0\x9F\x90\x95",
"U+1F416.png":  "\xF0\x9F\x90\x96",
"U+1F42A.png":  "\xF0\x9F\x90\xAA",
"U+1F465.png":  "\xF0\x9F\x91\xA5",
"U+1F46C.png":  "\xF0\x9F\x91\xAC",
"U+1F46D.png":  "\xF0\x9F\x91\xAD",
"U+1F4AD.png":  "\xF0\x9F\x92\xAD",
"U+1F4B6.png":  "\xF0\x9F\x92\xB6",
"U+1F4B7.png":  "\xF0\x9F\x92\xB7",
"U+1F4EC.png":  "\xF0\x9F\x93\xAC",
"U+1F4ED.png":  "\xF0\x9F\x93\xAD",
"U+1F4EF.png":  "\xF0\x9F\x93\xAF",
"U+1F4F5.png":  "\xF0\x9F\x93\xB5",
"U+1F500.png":  "\xF0\x9F\x94\x80",
"U+1F501.png":  "\xF0\x9F\x94\x81",
"U+1F502.png":  "\xF0\x9F\x94\x82",
"U+1F504.png":  "\xF0\x9F\x94\x84",
"U+1F505.png":  "\xF0\x9F\x94\x85",
"U+1F506.png":  "\xF0\x9F\x94\x86",
"U+1F507.png":  "\xF0\x9F\x94\x87",
"U+1F509.png":  "\xF0\x9F\x94\x89",
"U+1F515.png":  "\xF0\x9F\x94\x95",
"U+1F52C.png":  "\xF0\x9F\x94\xAC",
"U+1F52D.png":  "\xF0\x9F\x94\xAD",
"U+1F55C.png":  "\xF0\x9F\x95\x9C",
"U+1F55D.png":  "\xF0\x9F\x95\x9D",
"U+1F55E.png":  "\xF0\x9F\x95\x9E",
"U+1F55F.png":  "\xF0\x9F\x95\x9F",
"U+1F560.png":  "\xF0\x9F\x95\xA0",
"U+1F561.png":  "\xF0\x9F\x95\xA1",
"U+1F562.png":  "\xF0\x9F\x95\xA2",
"U+1F563.png":  "\xF0\x9F\x95\xA3",
"U+1F564.png":  "\xF0\x9F\x95\xA4",
"U+1F565.png":  "\xF0\x9F\x95\xA5",
"U+1F566.png":  "\xF0\x9F\x95\xA6",
"U+1F567.png":  "\xF0\x9F\x95\xA7",
"U+1F970.png":  "\xF0\x9F\xA5\xB0",
"U+1F923.png":  "\xF0\x9F\xA4\xA3",
"U+1F642.png":  "\xF0\x9F\x99\x82",
"U+1F643.png":  "\xF0\x9F\x99\x83",
"U+1F972.png":  "\xF0\x9F\xA5\xB2",
"U+1F979.png":  "\xF0\x9F\xA5\xB9",
"U+1F973.png":  "\xF0\x9F\xA5\xB3",
"U+1F641.png":  "\xF0\x9F\x99\x81",
"U+2639.png":  "\xE2\x98\xB9\xEF\xB8\x8F",
"U+1F97A.png":  "\xF0\x9F\xA5\xBA",
"U+1F92C.png":  "\xF0\x9F\xA4\xAC",
"U+1F92F.png":  "\xF0\x9F\xA4\xAF",
"U+1F975.png":  "\xF0\x9F\xA5\xB5",
"U+1F976.png":  "\xF0\x9F\xA5\xB6",
"U+1F917.png":  "\xF0\x9F\xA4\x97",
"U+1F929.png":  "\xF0\x9F\xA4\xA9",
"U+1F9D0.png":  "\xF0\x9F\xA7\x90",
"U+1F913.png":  "\xF0\x9F\xA4\x93",
"U+1F928.png":  "\xF0\x9F\xA4\xA8",
"U+1F92A.png":  "\xF0\x9F\xA4\xAA",
"U+1FAE5.png":  "\xF0\x9F\xAB\xA5",
"U+1F978.png":  "\xF0\x9F\xA5\xB8",
"U+1FAE1.png":  "\xF0\x9F\xAB\xA1",
"U+1F92B.png":  "\xF0\x9F\xA4\xAB",
"U+1FAE0.png":  "\xF0\x9F\xAB\xA0",
"U+1F925.png":  "\xF0\x9F\xA4\xA5",
"U+1FAE4.png":  "\xF0\x9F\xAB\xA4",
"U+1F92E.png":  "\xF0\x9F\xA4\xAE",
"U+1F922.png":  "\xF0\x9F\xA4\xA2",
"U+1F924.png":  "\xF0\x9F\xA4\xA4",
"U+1F971.png":  "\xF0\x9F\xA5\xB1",
"U+1F914.png":  "\xF0\x9F\xA4\x94",
"U+1FAE3.png":  "\xF0\x9F\xAB\xA3",
"U+1F92D.png":  "\xF0\x9F\xA4\xAD",
"U+1FAE2.png":  "\xF0\x9F\xAB\xA2",
"U+1F644.png":  "\xF0\x9F\x99\x84",
"U+1F910.png":  "\xF0\x9F\xA4\x90",
"U+1F974.png":  "\xF0\x9F\xA5\xB4",
"U+1F635 U+200D U+1F4AB.png":  "\xF0\x9F\x98\xB5\xE2\x80\x8D\xF0\x9F\x92\xAB",
"U+1F927.png":  "\xF0\x9F\xA4\xA7",
"U+1F912.png":  "\xF0\x9F\xA4\x92",
"U+1F915.png":  "\xF0\x9F\xA4\x95",
"U+1F911.png":  "\xF0\x9F\xA4\x91",
"U+1F920.png":  "\xF0\x9F\xA4\xA0",
"U+1F921.png":  "\xF0\x9F\xA4\xA1",
"U+2620.png":  "\xE2\x98\xA0\xEF\xB8\x8F",
"U+1F916.png":  "\xF0\x9F\xA4\x96"
}