<template>
  <div class="d-flex flex-column" style="gap: 8px; flex-wrap: wrap;">
    <div v-for="(video_path, key) in data" :key="key" class="d-flex justify-content-center">
       <video style="height: 400px" playsinline controls>
        <source
            :src="buildMediaUrl(video_path)"
            type="video/mp4">
      </video>
    </div>
  </div>
</template>

<script>
import attachments_mixin from "../../../util/attachments_mixin";
export default {
  name: "videos",
  mixins: [attachments_mixin],
  data() {
    return {
      message_type: 'video',
      data: [],
    }
  },
}
</script>

<style scoped>

</style>