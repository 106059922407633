import axios from "axios";

// require `message_type` component data
export default {
    async mounted() {
        this.data = await this.getMedia();
    },
    methods: {
        async getMedia() {
            let response = await axios.post(process.env.VUE_APP_URL + 'api/v2/getChatMedia', {
                instance_number: this.$store.getters.getSelectInstance,
                chat_id: this.$store.getters.getSelectChat.chat_id,
                message_type: this.message_type,
            });
            return response.data
        },
        buildMediaUrl(media_path) {
          return process.env.VUE_APP_URL + media_path
        },
    },
}