import axios from 'axios';
import {cssSelectRemove} from "../../util/main";

const state = {
    paginatorInfo: {},
    chatListBase: [],
    chatListSearch: {
        chat: [],
        chat_by_messages: []
    },
    selectChat: {
        chat_id: undefined,
        instance: undefined
    }
}
const getters = {
    getPaginatorInfoChat(state) {
        return state.paginatorInfo
    },
    getChatListSearch(state) {
        return state.chatListSearch
    },
    getChatListBase(state) {
        return state.chatListBase
    },
    getSelectChat(state) {
        return state.selectChat
    },
    getInfoChat: (state) => (chatID) => {
        console.log('In get INfo chat looking id:', chatID, 'with type:', typeof(chatID));
        const chatBase = state.chatListBase;
        console.log('current chatBase:', chatBase);
        return chatBase.find(chatInBase => chatInBase.chat_id === chatID);
    },
    getInfoChatChatListSearch: (state) => (chatID) => {
        return state.chatListSearch.chat_by_messages.find(chat => chat.chat_id === chatID)
    }
}
const mutations = {
    setChatListSearch(state, data) {
        console.log('data 12', data)

        if (data.content.length === 0) {
            data.content = 'Not fount'
        }

        if (data.type === 'chat') {
            state.chatListSearch.chat = data.content
        } else {
            state.chatListSearch.chat_by_messages = data.content
        }
    },
    resetChatListSearch(state) {
        state.chatListSearch.chat = []
        state.chatListSearch.chat_by_messages = []
    },
    setPaginatorInfoChat(state, data) {
        state.paginatorInfo = data;
    },
    addChatListBase(state, data) {
        console.log('adding new chat to list base in store with data:', data);
        data.forEach(element => {
            const existingChat = state.chatListBase.find(chatInBase => chatInBase.chat_id === element.chat_id);
            if (!(existingChat)) {
                let unreadMessaages = document.title.split(') ');
                let title = unreadMessaages[1] || unreadMessaages;
                if (unreadMessaages.length > 1) {
                    unreadMessaages = parseInt(
                        unreadMessaages[0]
                            .split('(')[1]
                    ) + element.count_unread_chat
                }
                else {
                    unreadMessaages = element.count_unread_chat
                }
                if (unreadMessaages > 0) {
                    title =  '(' + unreadMessaages.toString() + ') ' + title
                    document.title = title
                }

                state.chatListBase.push(element);
                console.log('pushed new element to chat base');
            }
            else {
                console.log('Chat already exists')
            }
        })

        state.chatListBase.sort((a, b) => b.is_pinned ? 1 : b.last_message - a.last_message)
    },
    resetChatListBase(state) {
        state.chatListBase = []
    },
    setSelectChat(state, data) {
        state.selectChat.chat_id = data.chat_id;
        state.selectChat.instance = data.instance;
    },
    resetChatUnreadCounter(state, chatId) {
        let chat = state.chatListBase.find(chat => chat.chat_id === chatId);
        if (chat) {
            let unreadMessaages = document.title.split(')')
            if (unreadMessaages.length > 1) {
                unreadMessaages = parseInt(unreadMessaages[0].split('(')[1]) - chat.count_unread_chat
            } else {
                unreadMessaages = 0;
            }

            if (unreadMessaages > 0) {
                document.title = '(' + unreadMessaages + ') ' + (document.title.split(') ')[1] || document.title)
            }
            else {
                document.title = document.title.split(') ')[1] || document.title
            }
            chat.count_unread_chat = 0
        }
    },
    updateLastChatMessage(state, {data, setUnread}) {
        let chat = state.chatListBase.find(chat => chat.chat_id === data.chat_id)
        if (chat) {
            if (setUnread) {
                console.log('setUnread: is True so updating count_unread_chat + 1')
                chat.count_unread_chat += 1
                let unreadMessaages = document.title.split(')')
                if (unreadMessaages.length > 1) {
                    unreadMessaages = parseInt(unreadMessaages[0].split('(')[1]) + 1
                } else {
                    unreadMessaages = 1
                }
                document.title = '(' + unreadMessaages + ') ' + (document.title.split(') ')[1] || document.title)
            }
            chat.last_message = data.content[0].time_as_timestamp
            chat.last_text_message_chat  = data.content[0].body_messages

            state.chatListBase.sort((a, b) => b.is_pinned ? 1 : b.last_message - a.last_message)
        }
    },
    updateChatData(state, data) {
        let chat = state.chatListBase.find(chat => chat.chat_id === data.chat_id)
        console.log('chat chatsss ', chat)

        if (chat) {
            if (data.last_text_message_chat) {
                chat.last_text_message_chat = data.last_text_message_chat
                chat.last_time_update_chat = data.last_time_update_chat
                chat.last_text_message_is_from_me = data.last_text_message_is_from_me
            }

            console.log('Данные для апдейта чата', data)
            if (data.new_timestamp) {
                chat.last_message = data.new_timestamp
            }

            if (chat.format_last_visit) {
                console.log('WORK 1233')
                chat.format_last_visit = data.format_last_visit
            }
            if (data.last_visit) {
                chat.last_visit = data.last_visit
            }

            if ('check_mark' in data) {
                chat.check_mark = data.check_mark
            }

            if (data.count_unread_chat) {
                chat.read_chat = false
                chat.count_unread_chat = 1
            }

            state.chatListBase.sort((a, b) => b.is_pinned ? 1 : b.last_message - a.last_message)
            console.log('chat2', chat)
        }
    },
    setReadChat(state, data) {
        let chat = state.chatListBase.find(chat => chat.chat_id === data.chat_id)
        if (chat) {
            if (!data.status) {
                chat.count_unread_chat += 1
            } else {
                chat.count_unread_chat = 0
            }

            chat.read_chat = data.status
        }
    }
}
const actions = {
    reloadChats(context) {
        console.log('in reloadChats. Context:', context);
        axios({
            method: 'get',
            url: process.env.VUE_APP_URL + `api/v2/getChats/${context.getters.getSelectInstance}/${context.getters.getSelectedStatus}/1`,
            headers: {'Content-Type': 'multipart/form-data'}
        })
            .then(reloadChatsResponse => {
            console.log('result of reloadChats:', reloadChatsResponse?.data);
            context.commit('setPaginatorInfoChat', reloadChatsResponse?.data?.page);
            console.log('made setPaginatorInfoChat commit with page:', reloadChatsResponse?.data?.page);
            context.commit('resetChatListBase');
            console.log('made resetChatListBase commit');
            context.commit('addChatListBase', reloadChatsResponse?.data?.content);
            console.log('made addChatListBase commit with', reloadChatsResponse?.data?.content);
            }).catch(error => {
                console.error('Error reloading chats:', error);
            });
        },
    updateNewChat(context, data) {
      const existingChat = state.chatListBase.find(chat => chat?.chat_id === data?.chat_id);
      if (existingChat) {
          try {
              const url = process.env.VUE_APP_URL + `api/v2/getChat/${data.instance}/${data.chat_id}`;
              axios({
                  method: 'get',
                  url: url,
                  headers: { 'Content-Type': 'multipart/form-data' }
              }).then(response => {
                  console.log('response after quering new created chat:', response);
                  context.commit('addChatListBase', [response.data['content']]);})
          } catch (e) {
              console.log('Error assigning new chat', e);
          }
    }},
    getChatsBase(context, data) {
        axios({
            method: 'get',
            url: process.env.VUE_APP_URL + `api/v2/getChats/${data.instance}/${data.status}/${data.page}`,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(result => {
            console.log('result result', result.data)
            context.commit('setPaginatorInfoChat', result.data['page'])
            context.commit('addChatListBase', result.data['content'])
            // upWebSocketConnections()
        })
    },
addChatByChatID(context, data) {
  const url = process.env.VUE_APP_URL + `api/v2/getChat/${data.instance}/${data.chat}`;

  return axios({
    method: 'get',
    url: url,
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  .then(response => {
    console.log('response after adding chat by id:', response);
    context.commit('addChatListBase', [response.data['content']]);
    return response.status;
  })
  .catch(e => {
    console.log('error.response during adding new chat by id', e);
    if (e.response && e.response.status === 404) {
      const data = e.response.data;
      context.commit('addChatListBase', [{
        avatar: "",
        chat_id: data.chat,
        check_mark: false,
        count_unread_chat: 0,
        description: "",
        format_last_visit: "",
        format_phone: "",
        id: -1,
        instance: 1,
        last_message: 0,
        last_text_message_chat: "",
        last_time_update_chat: "",
        last_visit: 1616431579,
        metadata: "{'isGroup': False, 'participants': [], 'groupInviteLink': None}",
        name: "Новый чат",
        nickname: "Новый чат",
        note: null,
        phone: data.chat.split('@')[0],
        read_chat: false,
        selectNew: false
      }]);
      console.log('commit to chat base made');
      return e.response.status;
    }
    throw e; // Ensure other errors are not swallowed
  });
},
    resetChat(context) {
        context.commit('resetChatListBase');
        context.commit('resetChatListSearch');
        context.commit('setSelectStatus', 'all');
    },
    startSearch(context, data) {
        return new Promise((resolve, reject) => {
            let typeSearch = data.textSearch ? 'messages' : 'chat'

            axios({
                method: 'get',
                url: process.env.VUE_APP_URL + `api/v2/searchChat/${data.instance}/${data.text}?type=${typeSearch}`,
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(result => {
                console.log('result result text', result.data)
                context.commit('setPaginatorInfoChat', {'has_next': false, 'page': 0})
                context.commit('setChatListSearch', {'content': result.data.chat, 'type': result.data.type})
                resolve(true)
            }).catch(() => {
                reject(false)
            })
        })
    },
    unReadChat(context) {
        let formData = new FormData();

        formData.append('chat', context.getters.getSelectChat.chat_id)
        formData.append('instance', context.getters.getSelectChat.instance)
        formData.append('operator_name', context.getters.getUserUsername);

        axios({
            method: 'post',
            url: process.env.VUE_APP_URL + 'api/v2/unreadMessages',
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then(() => {
            context.commit('updateChatData', {
                chat_id: context.getters.getSelectChat.chat_id,
                count_unread_chat: 1,
                read_chat: false
            })

            cssSelectRemove('selectChat')
            context.commit('resetMessage')
        })

    }
}

export default {
    state, getters, mutations, actions
}
