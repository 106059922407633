<template>
  <section class="w-full h-full" id="bod" v-if="$store.getters.getUserStaff">
    <div class="banner">
      <h2 style="text-align: center">Freonix</h2>
      <a>Стастика</a>
    </div>
    <div class="content">
      <div class="content-u">
        <h3 class="content-h3">Инстансы</h3>
        <table class="table-fixed">
          <thead>
          <tr>
            <th class="w-1/2">Инстанс</th>
            <th class="w-1/2">Отправленные</th>
            <th class="w-1/2">Принятые</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in instanceStats" v-bind:key="i.name">
            <td>{{ i.name }}</td>
            <td>{{ i.outgoing_messages }}</td>
            <td>{{ i.incoming_messages }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="content-i">
        <h3 class="content-h3">Пользователи</h3>

        <table class="table-fixed">
          <thead>
          <tr>
            <th class="w-1/2">Ник</th>
            <th class="w-1/3">Время</th>
            <th class="w-1/3">Отправленные</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in userStats" v-bind:key="i.username">
            <td>{{ i.username }}</td>
            <td> -</td>
            <td>{{ i.outgoing_messages }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

export default {
  name: "statistics",
  data() {
    return {
      instanceStats: {},
      userStats: {},
    }
  },
  created() {
    this.$store.dispatch('getUserInfo')
    axios({
      method: 'get',
      url: process.env.VUE_APP_URL + 'api/v2/stats/instance/',
    }).then(result => {
      this.instanceStats = result.data
    });
    axios({
      method: 'get',
      url: process.env.VUE_APP_URL + 'api/v2/stats/users/',
    }).then(result => {
      this.userStats = result.data
    })
  }
}
</script>

<style scoped>

.banner {
  background: white;
  text-align: center;
  padding: 1%;
}

.banner h2 {
  font-size: 40px;
}

.banner h2 {
  font-size: 30px;
}

.content {
  display: inline-flex;
  width: 100%;
}

.content-u {
  width: 50%;
}

.content-i {
  width: 50%;
}

.content-h3 {
  text-align: center;
  color: white;
  font-size: 20px;
  padding: 5%;
}

body {
  background: white;
}

table {
  margin-left: 10%;
  background: white;
}

</style>