<template>
 <div :id="`mapContainer${id}`"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  name: "interactive_map_google",
  props: {
    'center': Array,
    'id': String,
  },
  methods: {
    setupLeafletMap() {
     const mapDiv = L.map(`mapContainer${this.id}`).setView(this.center, 17);
     L.tileLayer(
       "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
       {
         maxZoom: 18,
         id: "mapbox/streets-v11",
         accessToken:"pk.eyJ1Ijoic3RhbGtlcnJhZnRpayIsImEiOiJjbDY1NTJocDAwMHIxM2NvZHVoc3JvZWRuIn0.jr_jNsRLezZG7g-ttKIwQw",
       }
     ).addTo(mapDiv);

     let LeafIcon = L.Icon.extend({
       options: {
         iconSize:     [32, 32],
         iconAnchor:   [16, 16],
         shadowAnchor: [4, 62],
         popupAnchor:  [-3, -76]
       }
     });

     let icon = new LeafIcon({
        iconUrl: process.env.VUE_APP_URL + 'map_pointer.png',
      })

     new L.Marker(this.center, {icon}).addTo(mapDiv)
    },
  },
  mounted() {
    this.setupLeafletMap();
  },
}
</script>

<style scoped>
</style>