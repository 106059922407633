<template>
  <div style="max-width: 45rem;" @click.alt="$parent.showInfo(id)"
       class="w-3/4 my-2 messagessees" :class="[from ? 'self-end' : 'self-start']">
    <image_full v-if="fullImage" @closeButtonPressed="fullImage = '';" :src="hostURL + showMedia.url" />
    <div class="messages">
      <div style="overflow: overlay;" class="p-4 text-sm bg-white rounded-t-lg rounded-r-lg d-flex flex-column">
        <div style="float: right;">
          <span class="dropbtn" @click="selectMessage(id,messages)" style="float: right; padding-right: 10px;"
                data-testid="down-context"
                data-icon="down-context">
            <img src="/svg/quotation_dark.png" alt="Ответить" style="width: 16px; height: 16px;">
          </span>


          <span class="dropbtn" style="float: right;" data-testid="down-context"
                @click="forwardMessage(type_messages, messages, id)" data-icon="down-context">


            <svg xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 16 16" width="16" style="color: black;"
                 height="16">
              <path fill="currentColor"
                    d="M 9.519 3.875 a 0.54 0.54 0 0 1 0.922 -0.382 l 4.03 4.034 a 0.54 0.54 0 0 1 0 0.764 l -4.03 4.034 a 0.54 0.54 0 0 1 -0.922 -0.383 v -1.821 c -3.398 0 -5.886 0.97 -7.736 3.074 c -0.164 0.186 -0.468 0.028 -0.402 -0.211 c 0.954 -3.449 3.284 -6.67 8.138 -7.363 V 3.875 Z"/></svg>


            </span>
        </div>

        <div v-if="quoted_messages" class="messages">
          <div
              style="overflow: overlay; background: #bee3f8 !important; margin-right: 30px !important; padding: 7px !important;"
              class="p-4 text-sm bg-white rounded-t-lg rounded-r-lg messages messages-mini">
            <a v-if="quoted_name !== true">вы</a>
            <a v-else>собеседник</a>
            <p v-if="!parsedQuotedMessages.startsWith('static/')" v-html="parsedQuotedMessages"/>
            <img v-else-if="quoted_messages.endsWith('.img') || quoted_messages.endsWith('.png')" :src="quoted_messages" :alt="quoted_messages">
            <div style="background-color: #bee3f8!important; display: flex; flex-direction: column" v-else>
              <span>ФАЙЛ: {{ quoted_messages.split('/')[quoted_messages.split('/').length - 1]}}</span>
              <a :href="quoted_messages" download style="margin-top: 24px; color: #4299e1;">Скачать</a>
            </div>
          </div>
        </div>

        <i v-if="isforwarded === 1">пеересланое:</i>

        <template v-if="type_messages==='chat'">
          <template v-if="messages.startsWith('http')">
            <a :href="messages" target="_blank" style="color: rgb(66, 153, 225)">{{ messages }}</a>
          </template>
          <p v-else v-html="corr(messages)" :style="{fontSize: !onlyEmojies(messages) ? '14px' : '30px'}"></p>
        </template>

        <template v-if="type_messages==='image'">
          <img
              @click="fullImage = true;"
              :src="showMedia.url.includes('https://s3')? showMedia.url: hostURL + showMedia.url">


          <p>{{ additional_data && additional_data.name || showMedia.text }}</p>
          <a :href="showMedia.url.includes('https://s3')? showMedia.url: hostURL + showMedia.url" target="_blank"
             style="padding-top: 20px;color: #4299e1;">Открыть</a>
          <p v-if="additional_data && additional_data.caption">{{ additional_data.caption }}</p>
        </template>

        <template v-if="type_messages==='video'">
          <video width="320" height="240" playsinline controls>
            <source
                :src="showMedia.url.includes('https://s3')? showMedia.url: hostURL + showMedia.url"
                type="video/mp4">
          </video>
          <p>{{ showMedia.text }}</p>
          <p v-if="additional_data && additional_data.caption">{{ additional_data.caption }}</p>
        </template>

        <template v-if="type_messages==='ptt' || type_messages==='audio'">
          <audio style="width: 100%" controls>
            <source :src="hostURL + messages">
          </audio>
          <p v-if="additional_data && additional_data.caption">{{ additional_data.caption }}</p>
        </template>

        <template v-if="type_messages==='document' || type_messages==='AnyFiles'">
          <pdf v-if="isPDF" :src="pdfSrc" />
          <h3 v-else>ФАЙЛ: {{ additional_data && additional_data.name || messages.split('/')[5] }}</h3>
          <a :href="type_messages==='document' ? hostURL + messages : messages" target="_blank"
             style="padding-top: 20px;color: #4299e1;">Скачать</a>
          <p v-if="additional_data && additional_data.caption">{{ additional_data.caption }}</p>
        </template>


        <template v-if="type_messages==='call_log'">
          <p style="color: darkred; font-weight: bold">{{ messages }}</p>
        </template>

        <template v-if="type_messages==='location'">
<!--          <img :src="`data:image/png;base64, ${this.locationData.jpegThumbnail}`" alt="Местоположение">-->
          <interactive_map_google style="width: 40vw; height: 300px;" :id="id" :center="[this.locationData.latitude, this.locationData.longitude]" />
        </template>

        <template v-if="type_messages==='vcard'">
          <h3>Контакатная карточка</h3>
          <br>
          Имя: {{ cardGenerate(messages).fn[0].value }}<br>
          Телефон: <a :href="'tel:'+cardGenerate(messages).tel[0].value">{{ cardGenerate(messages).tel[0].value }}</a>
          <template v-if="cardGenerate(messages).email">
            Почта: <a :href="'mailto:'+cardGenerate(messages).email[0].value">{{
              cardGenerate(messages).email[0].value
            }}</a>
          </template>
          <br>
          Остальной текст:<br>
          {{ cardGenerate(messages).url }}<br>

          <a target="_blank"
             :href="`/chat/`+ $store.getters.getSelectInstance +`/` + cardGenerate(messages).tel[0].value">
            <button style="display: flex; width: 100%; margin-top: 5%;"
                    class="justify-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-transparent rounded pt-1">
              Написать
            </button>
          </a>
        </template>


        <div class="messages-data" style="display: flex;justify-content: flex-end; padding-top: 10px; flex-direction: column; align-items: end;">
          <p style="text-align: right; font-size: 12px;">
            <template v-if="time_reading !== '1970-01-01 03:00'">
              <a v-if="from && time_reading">прочитано: {{ time_reading }}</a>
            </template>

            <a v-if="time_reading && !from" style="margin-left: 10px;">отправлено: {{ time_sending }}</a>
            <a v-else style="margin-left: 13px;">отправлено: {{ time_sending }}</a>
          </p>
          <a v-if="from && operator_sender && operator_sender.username" style="font-size: 12px;">
            отправитель: {{ operator_sender.username }}
          </a>

          <svg v-if="from && time_reading && time_reading !== '1970-01-01 03:00'"
               style="color: #4e99e2; margin-left: 5px;"
               xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 16 15" width="16"
               height="15">
            <path fill="currentColor"
                  d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path>
          </svg>
        </div>


      </div>
      <div v-if="!read && !from">
        <div v-observe-visibility="readMessages(id)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import image_full from "@/components/image_full";
import axios from "axios";
import vc from "vcard-parser";
import {EMOJI_REGEXP, getEmojisData, generatorEmojiHtml} from "@/util/main";
import interactive_map_google from "../interactive_map_google";
import {parseTextWithEmojies} from "../../util/main";
import pdf from 'vue-pdf'

export default {
  name: "message",
  components: { interactive_map_google, pdf, image_full },
  props: {
    'messages': String,
    'time_reading': String,
    'time_sending': String,
    'id': String,
    'from': Boolean,
    'type_messages': String,
    'read': Boolean,
    'quoted_messages': String,
    'quoted_name': Boolean,
    'message_number': Number,
    'isforwarded': Number,
    'additional_data': String,
    'operator_sender': Object,
  },
  data() {
    let emojisData = getEmojisData();
    return {
      hostURL: process.env.VUE_APP_URL,
      emojisData: emojisData,
      altEmoji: Object.values(emojisData),
      locationData: this.type_messages === 'location' ? JSON.parse(this.messages) : '',
      fullImage: false,
    }
  },
  computed: {
    isPDF() {
      return this.messages.split('.').at(-1) === 'pdf';
    },
    pdfSrc() {
      return process.env.VUE_APP_URL + this.messages;
    },
    parsedQuotedMessages() {
      return parseTextWithEmojies(this.quoted_messages);
    },
    showMedia() {
      if ((this.$props['type_messages'] === 'image' || this.$props['type_messages'] === 'video') && this.$props['messages'].includes('text')) {
        try {
          return JSON.parse(this.$props['messages'].replace(/'/g, '"'))
        } catch (e) {
          return this.$props['messages'].replace(/'/g, '"')
        }
      } else {
        return {'url': this.$props['messages']}
      }
    },
    altEmojiDict() {
      const altEmojiDict = {}
      for (const alt of this.altEmoji) {
        altEmojiDict[alt] = true;
      }
      return altEmojiDict
    }
  },
  methods: {
    isEmoji(string) {
      let unified_emoji_ranges = ['\ud83c[\udf00-\udfff]', '\ud83d[\udc00-\ude4f]', '\ud83d[\ude80-\udeff]'];

      let reg = new RegExp(unified_emoji_ranges.join('|'), 'g');


      return !!string.match(reg);

    },
    onlyEmojies(text) {
      const textWithoutEmojies = text.replaceAll(EMOJI_REGEXP, '')
      return textWithoutEmojies.length === 0
    },
    arrayRemove(arr, value) {
      return arr.filter(function (ele) {
        return ele != value;
      });
    },
    corr(text) {
      // let unified_emoji_ranges = ['\ud83c[\udf00-\udfff]', '\ud83d[\udc00-\ude4f]', '\ud83d[\ude80-\udeff]', 'u1wF932'];
      // let regex = new RegExp(unified_emoji_ranges.join('|'), 'g');
      // const found = text.match(regex);

      let tmp = []

      for (const [url, alt] of Object.entries(this.emojisData)) {
        if (tmp.includes(url)) {
          continue;
        }
        let html = generatorEmojiHtml(url, alt)
        text = text.split(alt).join(html);
        tmp.push(alt)
      }

      return text
    },
    readMessages(id) {
      if (!this.$store.getters.getUserStaff) {
        let formData = new FormData();

        formData.append('id_messages', id);
        formData.append('instance', this.$store.getters.getSelectChat.instance)
        formData.append('chat', this.$store.getters.getSelectChat.chat_id)
        formData.append('operator_name', this.$store.getters.getUserUsername);

        axios({
          method: 'post',
          url: process.env.VUE_APP_URL + 'api/v2/readMessages',
          data: formData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then()
        //
        // console.log('READ', true)
        // let unreadMessaages = document.title.split('(')
        // if (unreadMessaages.length > 1) {
        //   unreadMessaages = parseInt(unreadMessaages[unreadMessaages.length - 1].split(')')[0]) - 1
        //   document.title = document.title.split(' (')[0]
        //   if (unreadMessaages > 0) {
        //     document.title += ' (' + unreadMessaages.toString() + ')';
        //   }
        // }



        // this.$store.commit('setReadChat', {'chat_id': this.$store.getters.getSelectChat.chat_id, 'status': true});
        // self.$store.commit('editCountUnReadChat', {'id': self.$store.getters.getSelectInstance, 'count': -1});
      }
    },
    selectMessage(id, text) {
      this.$store.commit('setForward', {'id': id, 'text': text})
    },
    forwardMessage(messages_type, messages, id) {
      this.$store.dispatch('statusShowMF', {
        'show': true,
        'messages_type': messages_type,
        'messages': messages,
        'messages_id': id
      })
    },
    openModaIMG(img) {
      this.$store.commit('setModalShowBaseIMG', true);
      this.$parent.$parent.openImageModal(img)
    },
    cardGenerate(data) {
      return vc.parse(data);
    }
  },
  mounted() {
    if (this.$store.getters.getPaginatorInfoMessages.last_page <= 2) {
      setTimeout(() => {
        document.getElementById('chat-box').scrollIntoView(false);
      }, 150)
    }
  }
}
</script>

<style>
.self-end div div {
  background: #d8f4d7 !important;
}
</style>