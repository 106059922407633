<template>
  <div @drop.prevent="addFile" @dragover.prevent="loadFile" style="width: 70%;"
       class="relative flex w-full overflow-hidden antialiased bg-gray-200">

    <forward-modal></forward-modal>

    <!-- center -->
    <div class="relative flex flex-col flex-1" style="background: url('/fon-whatsapp.png') ">

      <nav-panel :new-chat-id="chatId"></nav-panel>

      <scroll></scroll>

      <div class="top-0 bottom-0 left-0 right-0 flex flex-col flex-1 overflow-hidden bg-transparent bg-bottom bg-cover">
        <div class="self-center flex-1 w-full overflow-y-scroll">
          <div id="chat-box" class="relative flex flex-col px-3 py-1 m-auto">

            <div class="flex content-center items-center w-full px-3 mt-6">
              <button
                  @click="$parent.nextPageMessages($store.getters.getSelectInstance, $store.getters.getPaginatorInfoMessages.chat_id)"
                  v-if="$store.getters.getPaginatorInfoMessages.has_next" style="flex: auto;"
                  class="justify-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                Загрузить еще
              </button>
            </div>
            <message v-for="(message, key) in messages" :key="key"
                     :id="message.messages_id"
                     :messages="message.body_messages"
                     :read="message.read"
                     :type_messages="message.type_messages"
                     :time_sending="message.time_sending"
                     :time_reading="message.time_reading"
                     :isforwarded="message.isForwarded"
                     :quoted_author="message.quoted_author"
                     :quoted_name="message.quoted_name"
                     :quoted_messages="message.quoted_messages"
                     :message_number="message.message_number"
                     :additional_data="message.additional_data"
                     :from="message.from_me"
                     :operator_sender="message.operator_sender"
            >
            </message>

            <modal v-if="$store.getters.modalShowBaseIMG"
                   :img="imgForModal"
                   :close-name="'setModalShowBaseIMG'" btn-left="Закрыть"></modal>

            <modal v-if="$store.getters.getModalShowInfo" title="Инфомация о сообщение" btn-left="закрыть"
                   :close-name="'setModalShowInfo'"
                   :description="$store.getters.getOperator.data"></modal>
          </div>
        </div>

        <!-- ForwardMessages select -->
        <div v-if="$store.getters.getForward.text"
             @click="$store.commit('setForward', {'id': undefined, 'text': undefined})"
             style="text-align: center; padding-top: 10px; padding-bottom: 10px; background: #d8f4d7;">
          <a v-html="quoteMessage"></a>
          <a style="padding-left: 30px;">[ Закрыть ]</a>
        </div>

        <div v-if="showEmoji"
             style="width: 400px; height: 300px; background: white; position: fixed; top: 55%!important;left: 60%!important; overflow: scroll;">
          <div style="width: 100%; text-align: center">
            <a style="text-align: center; padding: 10px; font-weight: bolder;">Смайлы</a>
            <a @click="showEmoji = !showEmoji" style="text-align: right">X</a>
          </div>
<!--          <span-->
<!--              v-for="(altEmoji, key) in emojisData"-->
<!--              :key="key"-->
<!--              style="font-size: 28px; cursor: pointer;"-->
<!--              @click="pasteEmoji(altEmoji);"-->
<!--          >-->
<!--            {{ altEmoji }}-->
<!--          </span>-->
          <img
              v-for="(altEmoji, srcEmoji, key) in emojisData"
              :key="key"
              :data-emoji-index="key"
              style="display: inline-block; width: 55px; height: 55px;"
              :src="srcEmoji"
              :alt="altEmoji"
              @click="pasteEmoji(srcEmoji, altEmoji);"
          />
        </div>
        <!--Поле ввода сообщения-->
        <div style="width: 60%; height: 15%; z-index: 10" class="items-center self-center w-full">
          <div class="w-full">
            <span style="position: absolute; top: 90%;">
                    <label style="display: none">Select file</label>
                        <input style="display: none" id="in-file" @change="addFileButton"
                               type="file"
                               class="p-1 focus:outline-none focus:shadow-none">
                        <button style="" onclick="document.getElementById('in-file').click()"
                                type="submit"
                                class="p-1 focus:outline-none focus:shadow-none">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24"
                                     height="24"><path
                                    fill="currentColor"
                                    d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"></path></svg>
                        </button>
                </span>

            <span style="position: absolute;top: 90%;right: 21%;">
                <span @click="showEmoji = !showEmoji" data-testid="smiley" data-icon="smiley" class=""><svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24" width="24" height="24"><path
                    fill="currentColor"
                    d="M9.153 11.603c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962zm-3.204 1.362c-.026-.307-.131 5.218 6.063 5.551 6.066-.25 6.066-5.551 6.066-5.551-6.078 1.416-12.129 0-12.129 0zm11.363 1.108s-.669 1.959-5.051 1.959c-3.505 0-5.388-1.164-5.607-1.959 0 0 5.912 1.055 10.658 0zM11.804 1.011C5.609 1.011.978 6.033.978 12.228s4.826 10.761 11.021 10.761S23.02 18.423 23.02 12.228c.001-6.195-5.021-11.217-11.216-11.217zM12 21.354c-5.273 0-9.381-3.886-9.381-9.159s3.942-9.548 9.215-9.548 9.548 4.275 9.548 9.548c-.001 5.272-4.109 9.159-9.382 9.159zm3.108-9.751c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962z"></path></svg></span>

                <button @click="send" type="submit"
                        class="p-1 focus:outline-none focus:shadow-none hover:text-blue-500">
                        <svg class="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24">
                                <path fill-rule="nonzero"
                                      d="M6.43800037,12.0002892 L6.13580063,11.9537056 C5.24777712,11.8168182 4.5354688,11.1477159 4.34335422,10.2699825 L2.98281085,4.05392998 C2.89811796,3.66698496 2.94471512,3.2628533 3.11524595,2.90533607 C3.53909521,2.01673772 4.60304421,1.63998415 5.49164255,2.06383341 L22.9496381,10.3910586 C23.3182476,10.5668802 23.6153089,10.8639388 23.7911339,11.2325467 C24.2149912,12.1211412 23.8382472,13.1850936 22.9496527,13.6089509 L5.49168111,21.9363579 C5.13415437,22.1068972 4.73000953,22.1534955 4.34305349,22.0687957 C3.38131558,21.8582835 2.77232686,20.907987 2.9828391,19.946249 L4.34336621,13.7305987 C4.53547362,12.8529444 5.24768451,12.1838819 6.1356181,12.0469283 L6.43800037,12.0002892 Z M5.03153725,4.06023585 L6.29710294,9.84235424 C6.31247211,9.91257291 6.36945677,9.96610109 6.44049865,9.97705209 L11.8982869,10.8183616 C12.5509191,10.9189638 12.9984278,11.5295809 12.8978255,12.182213 C12.818361,12.6977198 12.4138909,13.1022256 11.8983911,13.1817356 L6.44049037,14.0235549 C6.36945568,14.0345112 6.31247881,14.0880362 6.29711022,14.1582485 L5.03153725,19.9399547 L21.6772443,12.0000105 L5.03153725,4.06023585 Z"/>
                              </svg>
                </button>
            </span>

            <div style="margin-left: 25px">
              <a v-for="file in files" :key="file.name" v-on:click="remove(file)">
                <tag :text="file.name" background="#4299e1" text-color="white"></tag>
              </a>
            </div>

            <div id="textareaEmoji" ref="textareaEmoji" contenteditable="true"
                 @paste="onPaste"
                 v-show="!loadFileStatus"
                 style="border-radius: 25px; height: 110px; background: #e6e6e6"
                 v-on:keyup.enter="sendMessages"
                 class="textarea w-full py-2 pl-10 text-sm bg-white border border-transparent appearance-none rounded-tg placeholder-gray-800 focus:bg-white focus:outline-none focus:border-blue-500 focus:text-gray-900 focus:shadow-outline-blue">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- right -->
    <right-panel></right-panel>
  </div>
</template>

<script>
import NavPanel from "@/components/chat/navPanel";
import Scroll from "@/components/chat/scroll";
import Message from "@/components/chat/message";
import RightPanel from "@/components/chat/rightPanel";
import Modal from "../other/modal";
import axios from "axios";
import Tag from "../static/tag";
import ForwardModal from "../other/forwardModal";
import {getEmojisData, parseTextWithEmojies} from "@/util/main";
// import {Emoji} from 'emoji-mart-vue'

export default {
  name: "chatArea",
  components: {ForwardModal, Tag, Modal, RightPanel, Message, Scroll, NavPanel},
  props: {
    chatId: {type: String, required: false,  default: null},
  },
  data() {
    return {
      loadFileStatus: false,
      files: [],
      user_messages: '',
      imgForModal: '',
      emojisData: getEmojisData(),
      showEmoji: false
    }
  },
  computed: {
    quoteMessage() {
      return parseTextWithEmojies(this.$store.getters.getForward.text);
    },
    messages() {
      let messages = this.$store.getters.getMessages;
      messages.sort((a, b) => a.time - b.time);
      return messages
    }
  },
  methods: {
    openImageModal(img) {
      this.imgForModal = img
    },
    showInfo(id) {
      this.$store.commit('setModalShowInfo', true)
      this.$store.dispatch('getInfoMessages', {'id': id})
    },
    // paste img
    onPaste(evt) {
      let items = evt.clipboardData.items;

      if (items) {
        // находим изображение
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            let blob = items[i].getAsFile();
            let URLObj = window.URL || window.webkitURL;
            let source = URLObj.createObjectURL(blob);
            let url = this.createImage(source);
            this.loadFile();
            fetch(url).then(img => {
              const file = new File([blob], 'bufer.png', img);
              this.files.push(file);
            })

          }
        }
      }
    },
    createImage(source) {
      let pastedImage = new Image();
      pastedImage.onload = function () {
        // теперь у нас есть изображение из буфера
      }
      pastedImage.src = source;
      return pastedImage.src
    },

    // send img or file
    send(e) {
      if (this.loadFileStatus) {
        this.upload()
      } else {
        this.sendMessages(e)
      }
    },
    rTest(word, s_str, e_start) {
      if (!word.includes(s_str)) {
        return word
      }

      let str = [...word];
      // eslint-disable-next-line no-unused-vars
      let start, stop = null;


      str.forEach((letter, i) => {
        if (str[i + 4] !== undefined) {
          if (str[i] + str[i + 1] + str[i + 2] + str[i + 3] + str[i + 4] === s_str) {
            start = i
          }
          if (str[i] + str[i + 1] + str[i + 2] + str[i + 3] + str[i + 4] === e_start) {
            stop = i + 5
          }
        }
      })

      if (start != null && stop != null) {
        let r = word.substring(0, start) + word.substring(stop + 1, word.length)
        return this.rTest(r, s_str, e_start)
      }
    },
    replaceImg(html) {
      console.log('pre', html)
      console.log('post', this.rTest(html, "<img ", 'ext="'))
      return this.rTest(html, "<img ", 'ext="').replace(/<\/?[^>]+(>|$)/g, '').replace(/">/g, '')
    },
    sendMessages: function (e) {
      if (e.shiftKey) {
        return ''
      } else {
        console.log(this.$refs.textareaEmoji.innerHTML);
        let textArea = this.$refs.textareaEmoji;
        //Enter fix send
        if (textArea.innerHTML === '\n'.repeat(textArea.innerHTML.length)) return;


        let images = textArea.getElementsByTagName('img')
        console.log(images);
        while (images.length > 0) {
          const imgNode = images[0];
          const altNode = document.createTextNode(imgNode.alt);
          textArea.replaceChild(altNode, imgNode);
        }

        // const indexEnter = this.$refs.textareaEmoji.textContent.indexOf('\n');
        // if (indexEnter) {
        //   if (['*', '_', '~'].includes(this.$refs.textareaEmoji.textContent.charAt(indexEnter + 1)) || ['*', '_', '~'].includes(this.$refs.textareaEmoji.textContent.charAt(indexEnter - 1))) {
        //     this.$refs.textareaEmoji.innerText = this.$refs.textareaEmoji.textContent.substring(0, indexEnter) + this.$refs.textareaEmoji.textContent.substring(indexEnter + 1, this.$refs.textareaEmoji.textContent.length)
        //   }
        // }

        if (this.$ref) {
          setTimeout(() => {
            document.getElementById('chat-box').scrollIntoView(false);
          }, 10)
        }

        let message = this.$refs.textareaEmoji.innerText;
        if (e.key === 'Enter') {
          message = message.slice(0, -2);
        }

        if (message.replaceAll('\n', '') === '') {
          this.$refs.textareaEmoji.innerText = '';
          return '';
        }

        let data = {
          type: 'messages.send',
          chat_id: this.$store.getters.getSelectChat.chat_id,
          message: message,
          instance_id: this.$store.getters.getSelectChat.instance,
          operator_id: this.$store.getters.getUserID,
        }

        let last_message = this.$store.getters.getMessages.at(-1);
        if (last_message && !last_message.from_me) {
          let paginatorInfo = this.$store.getters.getPaginatorInfoChat;
          paginatorInfo.unanswerred_chats_count = paginatorInfo.unanswerred_chats_count - 1;
        }

        this.$store.commit('updateChatData', {
          'chat_id': this.$store.getters.getSelectChat.chat_id,
          'new_timestamp': Math.floor(Date.now() / 1000),
          'check_mark': false
        });

        if (this.$store.getters.getForward.id) {
          console.log('getForward', this.$store.getters.getForward)
          data['quotedMsgId'] = this.$store.getters.getForward.id
          data['quotedMsgText'] = this.$store.getters.getForward.text
          this.$store.commit('setForward', {'id': undefined, 'text': undefined})
        }

        // Отправляем сообщение по вебсокету и добавляем скелет сообщения в чат
        this.$store.commit('addSkeletonMessage', data);
        try {
          console.log('Current WS:', this.$store.getters.getCurrentWS);
          console.log('Data to send:', data);
          this.$store.getters.getCurrentWS.send(JSON.stringify(data));
          const chatId = this.$store.getters.getSelectChat.chat_id;
          this.$refs.textareaEmoji.innerText = '';
          if (this.$store.getters.getInfoChat(chatId).name === 'Новый чат') {
            console.log('going to reload chats after msg sent to new chat');
            setTimeout(() => this.$store.dispatch('reloadChats'), 1500);
          }
        } catch (e) {
          console.log('Error during sending to socket:', e)
        }
      }
    },
    upload() {
      let formData = new FormData();
      this.files.forEach((f, i) => {
        formData.append(`file${i}`, f);
        formData.append(`file${i}_name`, f.name);
      });

      formData.append('chat_id', this.$store.getters.getSelectChat.chat_id)
      formData.append('instance_id', this.$store.getters.getSelectChat.instance)
      formData.append('operator_id', this.$store.getters.getUserID)


      let self = this;

      axios({
        method: 'post',
        url: process.env.VUE_APP_URL + 'api/v2/sendFile',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      }).then(function () {
        self.loadFileStatus = false;
        self.files = [];
      }).catch(response => (console.log('error uploading', response)))
    },


    // add/delete files
    loadFile() {
      this.loadFileStatus = true;
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      // eslint-disable-next-line no-cond-assign
      for (let i = 0, f; f = droppedFiles[i]; i++) {
        this.files.push(f);
      }
    },
    remove(file) {
      const index = this.files.indexOf(file);
      if (index > -1) {
        this.files.splice(index, 1);
      }
      if (this.files.length === 0) {
        this.loadFileStatus = false;
      }
    },
    addFileButton(e) {
      this.loadFile();
      let droppedFiles = e.target.files;
      if (!droppedFiles) return;

      // eslint-disable-next-line no-cond-assign
      for (let i = 0, f; f = droppedFiles[i]; i++) {
        this.files.push(f);
      }
    },

    // Template
    useTemplate(text) {
      this.$refs.textareaEmoji.innerText = text
    },
    pasteEmoji(src, alt) {

      let html = alt;
      this.$refs.textareaEmoji.focus();

      let sel, range;
      if (window.getSelection) {
        // IE9 and non-IE
        sel = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
          range = sel.getRangeAt(0);
          range.deleteContents();

          // Range.createContextualFragment() would be useful here but is
          // non-standard and not supported in all browsers (IE9, for one)
          let el = document.createElement("img");
          el.innerHTML = html;
          el.src = src;
          el.alt = alt;
          el.style = "width: 20px; height: 20px; display: inline-block; margin-right: 2px;"
          // let frag = document.createDocumentFragment(), node, lastNode;
          // while ((node = el.firstChild)) {
          //   lastNode = frag.appendChild(node);
          // }
          range.insertNode(el);

          // Preserve the selection
          range = range.cloneRange();
          range.setStartAfter(el);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
      } else if (document.selection && document.selection.type !== "Control") {
        // IE < 9
        document.selection.createRange().pasteHTML(html);
      }
    },
  }
}
</script>

<style>
.textarea {
}

.modal {
  border: 5px solid #2c3e50;
  height: 200px;
  width: 1000px;
}

.emojik {
  margin: 6px;
  cursor: pointer;
  border-radius: 3px;
}

.emoji {
  border: none;
  width: 20px;
  height: 20px;
  image-rendering: -webkit-optimize-contrast;
}


.emoji.wa.b75, .emojik.wa.b75 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-75-40_59ecb60.webp");
}

.emoji.wa.b64, .emojik.wa.b64 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-64-40_279c1fa.webp");
}

.emoji.wa.b91, .emojik.wa.b91 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-91-40_b8ae509.webp");
}

.emoji.wa.b99, .emojik.wa.b99 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-99-40_761ba89.webp");
}

.emoji.wa.b3, .emojik.wa.b3 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-3-40_0b9fe45.webp");
}

.emoji.wa.b77, .emojik.wa.b77 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-77-40_6767439.webp");
}

.emoji.wa.b76, .emojik.wa.b76 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-76-40_53e46d5.webp");
}

.emoji.wa.b92, .emojik.wa.b92 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-92-40_b4cb2c1.webp");
}

.emoji.wa.b91, .emojik.wa.b91 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-91-40_b8ae509.webp");
}

.emoji.wa.b106, .emojik.wa.b106 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-106-40_24e2ebd.webp");
}

.emoji.wa.b89, .emojik.wa.b89 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-89-40_3341cbb.webp");
}

.emoji.wa.b78, .emojik.wa.b78 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-78-40_22df2e4.webp");
}

.emoji.wa.b88, .emojik.wa.b88 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-88-40_46e372a.webp");
}

.emoji.wa.b60, .emojik.wa.b60 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-60-40_7d099c5.webp");
}

.emoji.wa.b2, .emojik.wa.b2 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-2-40_2224eba.webp");
}

.emoji.wa.b25, .emojik.wa.b25 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-25-40_8b61961.webp");
}

.emoji.wa.b38 .emojik.wa.b38 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-38-40_8eab52b.webp");
}

.emoji.wa.b7, .emojik.wa.b7 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-7-40_1dd3846.webp");
}

.emoji.wa.b63, .emojik.wa.b63 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-63-40_33f9a55.webp");
}

.emoji.wa.b38, .emojik.wa.b38 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-38-40_8eab52b.webp");
}

.emoji.wa.b90, .emojik.wa.b90 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-90-40_204cc1a.webp");
}

.emoji.wa.b37, .emojik.wa.b37 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-37-64_4042dd6.webp");
}

.emoji.wa.b36, .emojik.wa.b36 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-36-64_e14a64a.webp");
}

.emoji.wa.b73, .emojik.wa.b73 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-73-40_c65e655.webp");
}

.emoji.wa.b81, .emojik.wa.b81 {
  background-image: url("http://webchat.asrx.ru:82/img/emoji-81-40_58bc633.webp");
}

.emojik.wa {
  background-size: 160px 160px;
}

.emoji.wa {
  background-size: 100px 100px;
}

.emojik {
  width: 32px;
  height: 32px;
  image-rendering: -webkit-optimize-contrast;
}

.emoji, .emojik {
  display: inline-block;
  vertical-align: top;
  zoom: 1;
}
</style>
