<template>
  <nav class="right-0 flex flex-col hidden pb-2 bg-white border-l border-gray-300 xl:block"
       style="width: 22rem; overflow: overlay;">

    <!--Информация-->
    <div style="padding-top: 2rem !important;">
      <p class="text-lg font-semibold text-center text-gray-800">{{ chatInfo ? chatInfo.nickname : '' }}<a
          v-if="chatInfo ? chatInfo.status: false"> [{{ chatInfo.status }}]</a></p>
      <p class="text-sm font-medium text-center text-blue-500">{{ chatInfo ? chatInfo.name : '' }}</p>
      <p class="text-sm font-medium text-center text-blue-500">+{{ chatInfo ? chatInfo.phone : '' }}</p>
      <p class="text-sm font-medium text-center text-blue-500">{{ chatInfo ? chatInfo.format_phone : '' }}</p>
    </div>

    <!--Форма-->
    <info-form></info-form>

    <!--Шаблоны-->
    <templateMessages></templateMessages>
  </nav>
</template>

<script>
import InfoForm from "@/components/chat/rightPanel/infoForm";
import templateMessages from "@/components/chat/rightPanel/templateMessages";

export default {
  name: "rightPanel",
  components: {templateMessages, InfoForm},
  computed: {
    chatInfo() {
      return this.$store.getters.getInfoChat(this.$store.getters.getPaginatorInfoMessages.chat_id) ? this.$store.getters.getInfoChat(this.$store.getters.getPaginatorInfoMessages.chat_id) : this.$store.getters.getInfoChatChatListSearch(this.$store.getters.getPaginatorInfoMessages.chat_id)
    }
  },
  methods: {
    useTemplate(text) {
      this.$parent.useTemplate(text)
    }
  }
}
</script>

<style scoped>

</style>