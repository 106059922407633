<template>
  <div>
    <!-- instance -->
    <tools ref="tools" @changeInstance="hiddenChatArea=true"></tools>

    <div class="relative flex w-full overflow-hidden antialiased bg-gray-200" style="height: 93vh;">
      <!-- chat bar -->
      <div v-if="$store.getters.getSelectInstance"
           class="relative flex flex-col hidden h-full bg-white border-r border-gray-300 shadow-xl md:block transform transition-all duration-500 ease-in-out"
           style="width: 30%">
        <!-- Поиск -->
        <search :is-searching="isSearching" @redirectUrl="redirectURL" @loadSerch="updateSerch"></search>

        <!-- Фильтры -->
        <category style="margin-left: 19px;"></category>

        <!-- Прочитать всё -->
        <read_all_button/>

        <!-- Контакты -->
        <div
            class="max-height-chat relative mt-2 mb-4 overflow-x-hidden overflow-y-auto scrolling-touch lg:max-h-sm scrollbar-w-2 scrollbar-track-gray-lighter scrollbar-thumb-rounded scrollbar-thumb-gray">
          <ul class="flex flex-col inline-block w-full px-2 select-none">

            <div class="loader" v-show="loadSerch">Loading...</div>

            <!-- Контакт -->
            <template
                v-if="$store.getters.getChatListBase && ($store.getters.getChatListSearch.chat.length === 0 && $store.getters.getChatListSearch.chat_by_messages.length === 0)">
              <chat v-for="chat in $store.getters.getChatListBase"
                    :key="$store.getters.getSelectInstance + '_' + chat.id" :id="chat.chat_id"
                    :ChatID="chat.chat_id"
                    :avatar="chat.avatar"
                    :name-contact="chat.name" :nick-name="chat.nickname" :short-description="chat.description"
                    :last-message="chat.last_text_message_chat" :data="String(chat.last_time_update_chat)"
                    :read-chat="chat.read_chat" :status="chat.status" :phoneContact="chat.format_phone"
                    :count_unread="chat.count_unread_chat" :selectNew="chat.selectNew"
                    :metadata="chat.metadata"
                    :last_message="chat.last_message" :method="'openChat'" :readLastMessage="chat.check_mark"
                    :from-me="chat.last_text_message_is_from_me" :is-pinned="chat.is_pinned"
              />
            </template>

            <template v-else>

              <div
                  v-if="$store.getters.getChatListSearch.chat.length !== 0 && $store.getters.getChatListSearch.chat !== 'Not fount'">
                <h1>Чаты</h1>
                <chat v-for="chat in this.$store.getters.getChatListSearch.chat"
                      :key="$store.getters.getSelectInstance + '_' + chat.id" :id="chat.chat_id"
                      :ChatID="chat.chat_id"
                      :avatar="chat.avatar"
                      :name-contact="chat.name" :nick-name="chat.nickname" :short-description="chat.description"
                      :last-message="chat.last_text_message_chat" :data="String(chat.last_time_update_chat)"
                      :read-chat="chat.read_chat" :status="chat.status" :phoneContact="chat.format_phone"
                      :count_unread="chat.count_unread_chat" :selectNew="chat.selectNew" :method="'openChat'"
                      :metadata="chat.metadata" :from-me="chat.last_text_message_is_from_me" :is-pinned="chat.is_pinned"
                      :last_message="chat.last_message"
                />
              </div>
              <div v-show="$store.getters.getChatListSearch.chat === 'Not fount'">
                <h1 style="text-align: center">Чатов не найдено</h1>
              </div>

              <div
                  v-if="$store.getters.getChatListSearch.chat_by_messages.length !== 0 && $store.getters.getChatListSearch.chat_by_messages !== 'Not fount'">
                <h1>Сообщение</h1>
                <chat v-for="chat in this.$store.getters.getChatListSearch.chat_by_messages"
                      :key="$store.getters.getSelectInstance + '_' + chat.id + '_' + chat.message_number"
                      :id="chat.chat_id"
                      :ChatID="chat.chat_id"
                      :avatar="chat.avatar"
                      :name-contact="chat.name" :nick-name="chat.nickname" :short-description="chat.description"
                      :last-message="chat.last_text_message_chat" :data="String(chat.last_time_update_chat)"
                      :read-chat="chat.read_chat" :status="chat.status" :phoneContact="chat.format_phone"
                      :count_unread="chat.count_unread_chat" :selectNew="chat.selectNew"
                      :metadata="chat.metadata" :from-me="chat.last_text_message_is_from_me" :is-pinned="chat.is_pinned"
                      :method="'openChat'" :start_number_messages="chat.message_number"
                      :last_message="chat.last_message"
                />
              </div>

              <div v-show="$store.getters.getChatListSearch.chat_by_messages === 'Not fount'">
                <h1 class="mt-2" style="text-align: center">Сообщений не найдено</h1>
              </div>

            </template>


          </ul>
        </div>

        <div class="flex-row justify-between px-3 pb-1 text-white">
          <div class="flex items-center w-full pl-2">
            <div
                class="flex flex-no-wrap items-center pr-3 text-black rounded-lg cursor-pointer content-center items-center w-full px-3">
              <button v-if="$store.getters.getPaginatorInfoChat.has_next" style="flex: auto;" @click="nextPage"
                      class="justify-center bg-transparent hover:bg-blue-500 text-blue-700 font-semibold py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                Загрузить еще
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- chat Area -->
      <chat-area
          v-if="chatAreaStatus"
          :chat-id="newChatId"
      ></chat-area>
    </div>
  </div>
</template>

<script>
import tools from "@/components/static/tools";
import category from "@/components/static/category";
import Chat from "@/components/static/chat";
import axios from "axios";
import {cssSelectRemove} from "../util/main";
import Search from "../components/chat/search";
import ChatArea from "../components/chat/chatArea";
import read_all_button from "@/components/static/read_all_button";

export default {
  name: 'Telegram',
  components: {ChatArea, Search, Chat, category, tools, read_all_button},
  data() {
    return {
      loadSerch: false,
      hiddenChatArea: false,
      socket: undefined,
      newChatId: null,
      isSearching: false,
      openingFromUrl: false,
    }
  },
  computed: {
    chatAreaStatus() {
      if (this.hiddenChatArea) {
        return false
      }

      if (this.$store.getters.getMessages.length > 0) {
        return true;
      } else {
        const selectChatID = this.$store.getters.getSelectChat.chat_id

        if (selectChatID) {
          if (this.$store.getters.getInfoChat(selectChatID)) {
          //   console.log('s12', this.$store.getters.getInfoChat(selectChatID).name === 'Новый чат')
          //   return this.$store.getters.getInfoChat(selectChatID).name === 'Новый чат'
          // } else {
            return true
          }
        }
      }

      return false
    }
  },
  methods: {
    readAllMessagesInChat() {
      let formData = new FormData();
      console.log('forming data to read all messages');
      formData.append('instance', this.$store.getters.getSelectChat.instance);
      formData.append('chat', this.$store.getters.getSelectChat.chat_id);
      formData.append('operator', this.$store.getters.getUserUsername);
      console.log('Formed data. Selected Chat is', this.$store.getters.getSelectChat);
      axios({
        method: 'post',
        url: process.env.VUE_APP_URL + 'api/v2/readAllMessages',
        data: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      console.log('sent read all messages post');
    },
    addSelectedChatClassById(id, interval = 100) {
      const checkElement = () => {
        const element = document.getElementById(id);
        if (element) {
          console.log('found chat element', element);
          element.classList.add('selectChat');
        } else {
          setTimeout(checkElement, interval);
        }
      };
      checkElement();
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    redirectFromUrlParams(instance, chat) {
      const correctChatId = this.correctChatId(chat);
      this.delay(1500).then(() => {
        this.$refs.tools.setInstance(instance, 'click');
      const instanceElement = document.getElementById(instance);
        console.log('Found instance element in redirectFromUrlParams:', instanceElement);
        if (instanceElement && !instanceElement?.classList?.contains('selectInstance')) {
          instanceElement?.classList?.add('selectInstance');
          console.log('added select instance class');
        }
        this.$store.dispatch('startSearch', {
        'text': correctChatId,
        'instance': instance,
        'globalSearch': false,
        'textSearch': false,
      }).then(() => {
        this.loadSerch = false;
        this.$nextTick()
            .then(() => this.openChat(instance, correctChatId + '@c.us'))
            .then(() => this.$router.push({ path: '/' }))
            .catch(error => {
              console.error('Error in redirecting to existing chat:', error);
            });
      })
      });
    },
    correctChatId(chatNum) {
      let cleanChatId = chatNum.replace(/%20/g, "")
          .replace(/-/g, "")
          .replace(/\(/g, "")
          .replace(/\)/g, "")
          .replace(/\+/g, "")
          .replace(/ /g, "");
      cleanChatId = `7${cleanChatId.substr(1)}`;
      return cleanChatId;
    },
    openChat(instance_id, chat_id, start_number_messages, newChat) {
      this.newChatId = chat_id;
      console.log(
        'Opening a new chat. ' +
        `instance_id: ${instance_id}, ` +
        `chat_id: ${chat_id}, ` +
        `start_number_messages: ${start_number_messages}, ` +
        `newChat: ${newChat}`
      );
      this.hiddenChatArea = false;
      cssSelectRemove('selectChat');
      this.addSelectedChatClassById(chat_id, 100);
     // update unread counter
      console.log('updating unread counter');
      let selected_chat = this.$store.getters.getInfoChat(chat_id);
      let currentChatBase = this.$store.getters.getChatListBase;
      let selected_chat_without_store = currentChatBase.find(chat => chat.chat_id === chat_id);
      console.log('Current chatList base:', currentChatBase);
      console.log('Selected chat:', selected_chat);
      console.log(selected_chat_without_store);

      // checking types
      console.log('Type of currentChatBase:', Array.isArray(currentChatBase)); // Check if it's an array
      console.log('Type of chat_id:', typeof chat_id); // Check the type of chat_id
      for (const chat of currentChatBase) {
        console.log('Chat ID in base:', chat.chat_id, 'Type:', typeof chat.chat_id)
      }

      let paginatorInfo = this.$store.getters.getPaginatorInfoChat;
      console.log('Paginator info:', paginatorInfo);
      if (selected_chat?.count_unread_chat > 0) {
        console.log('Selected chat has count_unread_chat more than zero, so updating paginatorInfo.unread_chats_count');
        paginatorInfo.unread_chats_count = paginatorInfo.unread_chats_count - 1;
      }

      // update instance unread state
      console.log('updating instance unread state');
      console.log('Selected instance:', this.$store.getters.getSelectInstance);
      console.log(paginatorInfo.unread_chats_count);
      if (paginatorInfo.unread_chats_count <= 0) {
        console.log('paginatorInfo unread_chats_count le than zero');
        this.$store.commit('editHasUnreadMessagesToInstance', {
          phone: this.$store.getters.getSelectInstance,
          hasUnread: false,
        });
        console.log('edited unread messages to instance');
      }
      this.$store.commit('resetMessage');
      console.log('reseted message');
      this.$store.commit('setForward', {'id': undefined, 'text': undefined});
      console.log('set forward');
      this.$store.commit('resetChatUnreadCounter', chat_id);
      console.log('reseted chat unread counter');
      // may be
      this.$store.commit('setSelectChat', {'chat_id': chat_id, 'instance': instance_id});
      console.log('set selected chat');
      if (!newChat) {
        this.readAllMessagesInChat();
      }
      console.log('current ws state:', this.$store.getters.getCurrentWS.readyState);
      if (!newChat) {
        this.$store.dispatch('getPackMessages', {
        'chat_id': chat_id,
        'instance_id': instance_id,
        'last_number': this.$store.getters.getPaginatorInfoMessages.last_page,
        'token': localStorage.getItem('token'),
        'start_number_messages': start_number_messages,
        'ws': this.$store.getters.getCurrentWS,
      });
      console.log('got pack messages');
      }

    },
    nextPageMessages(instance_id, chat_id) {
      this.$store.commit('editLastPage', this.$store.getters.getPaginatorInfoMessages.last_page);

      this.$store.dispatch('getPackMessages', {
        'chat_id': chat_id,
        'instance_id': instance_id,
        'last_number': this.$store.getters.getPaginatorInfoMessages.last_page,
        'ws': this.$store.getters.getCurrentWS
      })
    },
    nextPage() {
      this.$store.dispatch('getChatsBase', {
        instance: this.$store.getters.getSelectInstance,
        status: this.$store.getters.getSelectedStatus,
        page: this.$store.getters.getPaginatorInfoChat.page + 1
      })
    },
    clickCopy(phone) {
      this.counter++;

      if (this.counter === 1) {
        this.timer = setTimeout(() => {
          this.counter = 0;
        }, 300);

        return;
      }
      if (this.counter === 2) {
        this.$copyText(phone);
      }
      clearTimeout(this.timer);
      this.counter = 0;

    },
    redirectURL(instance, chat) {
      console.log('redirecting url:', instance, chat);
      console.log('this.$route.params', this.$route.params);
      let param_url = (instance && chat) ? { instance: instance, chat: chat } : this.$route.params;
      console.log('param_url:', param_url);
      if (param_url?.instance && param_url?.chat) {
        console.log('Going to set instance. param_url:', param_url);
        // const target = withUrlParams ? 'withUrlParams' : 'outside';
        this.$refs.tools.setInstance(param_url.instance);
        this.$nextTick().then(() => {
          param_url.chat = this.correctChatId(param_url.chat);
        console.log(param_url.chat);
        const getInfoChatResult = this.$store.getters.getInfoChat(param_url.chat + '@c.us');
        console.log('info chat result:', getInfoChatResult)
        if (getInfoChatResult) {
          console.log('found chat, going to open existing one');
          this.$nextTick()
            .then(() => this.openChat(param_url.instance, param_url.chat + '@c.us'))
            .then(() => this.$router.push({ path: '/' }))
            .catch(error => {
              console.error('Error in redirecting to existing chat:', error);
            });
        } else {
          console.log('Chat not found, going to create a new one');
          this.$store.dispatch('addChatByChatID', {
            instance: param_url.instance,
            chat: param_url.chat + '@c.us',
          })
            .then((responseStatus) => {
              console.log('response status after adding chat by Id:', responseStatus);
              if (responseStatus === 404) {
                console.log('created new chat and going to open it');
                console.log('Current chat list base:', this.$store.getters.getChatListBase);
                return this.openChat(
                  param_url.instance,
                param_url.chat + '@c.us',
                undefined, true);
            } else {
                this.openChat(param_url.instance,
                param_url.chat + '@c.us');
              }
            })
            .then(() => this.$router.push({ path: '/' }))
            .catch(error => {
              console.error('Error in redirecting to new chat:', error);
            });}
        })
        }
    },
    updateSerch(value) {
      console.log('value', value)
      this.loadSerch = value
    }
  },
  mounted() {
    const chat = this.$route.params?.chat ?? null;
    const instance = this.$route.params?.instance ?? null;
    if (chat && instance) {
      this.redirectFromUrlParams(instance, chat);
    } else {
      this.redirectURL(instance, chat);
    }
    Notification.requestPermission();
  }
}
</script>


<style>
.selectChat {
  background: #bee3f8;
}

.loader {
  color: #bee3f8;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.max-height-chat {
  max-height: 70%;
}

@media screen and (max-height: 730px) {
  .max-height-chat {
    max-height: 60%
  }
}
</style>