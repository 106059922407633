<template>
  <div class="d-flex flex-column" style="gap: 8px; margin: 8px 0;">
    <div
        v-for="(docs_object, key) in data" :key="key"
        style="width: 98%; box-shadow: rgb(0 0 0 / 50%) 0 0 5px; margin-left: 1%; margin-top: 8px; padding: 8px;">
      <div class="d-flex flex-column justify-content-center align-items-center" style="width: 100%;">
        <div class="d-flex justify-content-between align-items-center mb-2" style="width: 90%">
          <span>{{ getDocName(docs_object) }}</span>
          <a :href="buildMediaUrl(docs_object.body_messages)" target="_blank" download>Скачать</a>
        </div>
        <pdf v-if="isPDF(docs_object)" :src="buildMediaUrl(docs_object.body_messages)" style="width: 50%;" />
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import attachments_mixin from "../../../util/attachments_mixin";
export default {
  name: "docs",
  mixins: [attachments_mixin],
  components: { pdf },
  data() {
    return {
      message_type: 'document',
      data: [],
    }
  },
  methods: {
    getDocName(doc_obj) {
      if (doc_obj.additional_data && doc_obj.additional_data.name) {
        return doc_obj.additional_data.name;
      }
      return doc_obj.body_messages.split('/').at(-1);
    },
    isPDF(doc_obj) {
      return doc_obj.body_messages.split('.').at(-1) === 'pdf';
    },
  }
}
</script>

<style scoped>

</style>