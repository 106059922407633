<template>
  <div class="d-flex flex-column" style="gap: 8px;">
    <div v-for="(media_path, key) in data" :key="key" style="width: 100%;">
      <audio style="width: 100%" controls>
        <source :src="buildMediaUrl(media_path)">
      </audio>
    </div>
  </div>
</template>

<script>
import attachments_mixin from "../../../util/attachments_mixin";
export default {
  name: "audios",
  mixins: [attachments_mixin],
  data() {
    return {
      message_type: 'audio',
      data: [],
    }
  },
}
</script>

<style scoped>

</style>