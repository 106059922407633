<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  created() {

    // check ip
    this.$http({
      method: 'post',
      url: process.env.VUE_APP_URL + 'api/v2/auth/checkIP',
      headers: {'Content-Type': 'multipart/form-data'}
    }).then(result => {
      if (result.status === 203) {
        this.$router.push('/deny')
      }
    })

    // check token
    if (localStorage.getItem('token')) {
      let formData = new FormData()
      formData.append('token', localStorage.getItem('token'))
      this.$http({
        method: 'post',
        data: formData,
        url: process.env.VUE_APP_URL + 'api/v2/token/verify/',
        headers: {'Content-Type': 'multipart/form-data'}
      }).catch(err => {
        if (err.response.status === 401) {
          this.$store.dispatch("logout")
          this.$router.push('/')
        }
      })
    }
  }
}
</script>

<style>
</style>
