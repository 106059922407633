<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto" style="z-index: 94 !important;">
    <div class="flex items-end justify-center h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div style="margin-bottom: 25%; max-width: 75rem"
           class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
           role="dialog" aria-modal="true" aria-labelledby="modal-headline">

        <div @click="$store.commit(closeName, false)" class="modal-close cursor-pointer z-50">
          <svg style="float: right; margin: 10px; color: red;" class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="28"
               height="28"
               viewBox="0 0 18 18">
            <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>

        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <img v-if="img && ($store.getters.getModalShowIMG || $store.getters.modalShowBaseIMG)" style="width: 100%;"
               :src="img">
          <div v-else class="sm:flex sm:items-start" style="align-items: center; flex-flow: column;">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {{ title }}
              </h3>
              <div class="mt-2" v-html="description">
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <!--          <button @click="$store.commit(closeName, false)" type="button"-->
          <!--                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">-->
          <!--            {{ btnLeft }}-->
          <!--          </button>-->
          <!--          <button type="button"-->
          <!--                  class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">-->
          <!--            {{ btnRight }}-->
          <!--          </button>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    title: String,
    description: String,
    btnLeft: String,
    btnRight: String,
    img: String,
    closeName: String
  }
}
</script>

<style scoped>

</style>