<template>
  <div class="flex-row justify-between px-3 pt-1 text-white">
    <div class="flex items-center w-full py-2">
      <div
          class="relative flex items-center w-full overflow-hidden text-gray-600 focus-within:text-gray-400">
                <span class="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button type="submit" class="p-1 focus:outline-none focus:shadow-none">
                    </button>
                </span>
        <input type="search" v-model="text"
               class="py-2 pl-3 text-sm text-white bg-gray-200 border border-transparent appearance-none rounded-tg focus:bg-white focus:outline-none focus:border-blue-500 focus:text-gray-900 focus:shadow-outline-blue"
               style="border-radius: 25px; color: black !important; width: 45%;"
               placeholder="Поиск..." autocomplete="off" @keydown.enter="search">
        <button @click="search"
                class="justify-center bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-white py-1 px-1 pr-1 ml-2 mr-2  border hover:border-transparent rounded"
                style="flex:1 1 auto; font-size: 12px; width: 10%;">
          Поиск
        </button>

        <input type="tel" name="phone" v-model="phone"
               class="py-2 pl-12 text-sm text-white bg-gray-200 border border-transparent appearance-none rounded-tg focus:bg-white focus:outline-none focus:border-blue-500 focus:text-white-900 focus:shadow-outline-blue hover:text-white-900"
               style="border-radius: 25px; color: black !important; width: 45%;"
               placeholder="Номер контакта..." autocomplete="off" @keydown.enter="addContact">

        <button v-if="!isSearching" @click="addContact"
                class="justify-center bg-transparent hover:bg-blue-500 text-gray-500 font-semibold hover:text-white py-1 pl-1 px-1 ml-2 mr-2 border hover:border-transparent rounded"
                style="flex:1 1 auto; font-size: 14px; width: 5%;">
          +
        </button>
        <loader-spin v-if="isSearching"/>
      </div>
    </div>

    <div class="flex items-center w-full pl-2">
      <label v-if="$store.getters.getUserStaff">
        <input type="checkbox" class="appearance checked:bg-blue-600 checked:border-transparent"
               v-model="isGlobalSearch">
        <a style="color: black"> Глобальный</a>
      </label>

      <label class="pl-4">
        <input type="checkbox" class="appearance checked:bg-blue-600 checked:border-transparent" v-model="isTextSearch">
        <a style="color: black"> В сообщениях</a>
      </label>
    </div>
  </div>
</template>

<script>

import LoaderSpin from "@/components/other/LoaderSpin.vue";

export default {
  name: "search",
  components: {LoaderSpin},
  props: {
    isSearching: {type: Boolean, required: true,  default: false},
  },
  data() {
    return {
      'text': '',
      'phone': '',
      'isGlobalSearch': false,
      'isTextSearch': false,
    }
  },
  methods: {
    search() {
      console.log('Test');
      this.$emit('loadSerch', true);
      this.$store.dispatch('startSearch', {
        'text': this.text,
        'instance': this.isGlobalSearch ? 'all' : this.$store.getters.getSelectInstance,
        'globalSearch': this.isGlobalSearch,
        'textSearch': this.isTextSearch,
      }).then(() => {
        this.$emit('loadSerch', false)
      })
    },
    showContactBlock() {
      this.showAddContact = !this.showAddContact
    },
    correctPhone: function (phone) {
      phone = phone.replace(/-/g, "");
      phone = phone.replace(/\(/g, "");
      phone = phone.replace(/\)/g, "");
      phone = phone.replace(/\+/g, "");
      phone = phone.replace(/ /g, "");
      phone = 7 + phone.substr(1)
      return phone
    },
    addContact() {
      if (!this.isSearching) {
        this.phone = this.correctPhone(this.phone);
        this.$parent.redirectURL(this.$store.getters.getSelectInstance, this.phone);
      }
    }
  }
}
</script>

<style scoped>

</style>