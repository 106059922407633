<template>
  <div
      class="d-flex justify-content-center align-items-center"
       style="position: absolute; width: 100%; height: 100%; background-color: rgba(0,0,0,0.3); z-index: 50; top: 0; left: 0;"
  >
    <div class="d-flex flex-column" style="background-color: white; padding: 16px; border-radius: 15px; width: 80%; height: 80%;">
      <div class="d-flex justify-content-end align-items-center" style="width: 100%; cursor: pointer;">
        <span @click="$emit('closeWindow')" style="font-size: 20px; font-weight: 500;">X</span>
      </div>
      <div class="d-flex flex-row">
        <div
            @click="selected = 'images';" :class="{'attachments-button-selected-box': selected === 'images'}"
            class="d-flex justify-content-center align-items-center attachments-button attachments-button-divider"
        >
          <div :class="{'attachments-button-selected': selected === 'images'}" >
            Изображения
          </div>
        </div>
        <div
            @click="selected = 'video';" :class="{'attachments-button-selected-box': selected === 'video'}"
            class="d-flex justify-content-center align-items-center attachments-button attachments-button-divider"
        >
          <div :class="{'attachments-button-selected': selected === 'video'}" >
            Видео
          </div>
        </div>
        <div
            @click="selected = 'audio';" :class="{'attachments-button-selected-box': selected === 'audio'}"
            class="d-flex justify-content-center align-items-center attachments-button attachments-button-divider"
        >
          <div :class="{'attachments-button-selected': selected === 'audio'}" >
            Аудио
          </div>
        </div>
        <div
            @click="selected = 'docs';" :class="{'attachments-button-selected-box': selected === 'docs'}"
            class="d-flex justify-content-center align-items-center attachments-button"
        >
          <div :class="{'attachments-button-selected': selected === 'docs'}" >
            Документы
          </div>
        </div>
      </div>
      <div style="flex-grow: 1; overflow-y:scroll; margin-top: 8px">
        <images v-if="selected === 'images'" />
        <videos v-else-if="selected === 'video'" />
        <audios v-else-if="selected === 'audio'"/>
        <docs v-else-if="selected === 'docs'" />
      </div>
    </div>
  </div>
</template>

<script>
import images from "./attachments/images";
import videos from "./attachments/videos";
import docs from "./attachments/docs";
import audios from "./attachments/audios";

export default {
  name: "attachments",
  components: {videos, images, audios, docs},
  data() {
    return {
      selected: 'images',
    }
  }
}
</script>

<style scoped>
  .attachments-button {
    height: 60px;
    flex: 0 0 25%;
    font-size: 14px;
    font-family: Helvetica, Arial, sans-serif;
    cursor: pointer;
  }

  .attachments-button:hover {
    background-color: moccasin;
  }

  .attachments-button-selected-box {
    background-color: white!important;
  }

  .attachments-button-selected {
    border-bottom: 2px darkorange solid;
  }

  .attachments-button-divider {
    border-right: black 1px solid;
  }
</style>