<template>
  <h1 style="text-align: center; font-size: 90px; color: white">Доступ запрещен</h1>
</template>

<script>
export default {
  name: "deny",
  created() {
    // check ip
    this.$http({
      method: 'post',
      url: process.env.VUE_APP_URL + 'api/v2/auth/checkIP',
      headers: {'Content-Type': 'multipart/form-data'}
    }).then(result => {
      if (result.status === 203) {
        this.$router.push('/deny')
      }
      if (result.status === 200) {
        this.$router.push('/')
      }
    })
  }
}
</script>

<style scoped>

</style>